import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Theme,
  Typography
} from '@material-ui/core';

import { TransitionProps } from '@material-ui/core/transitions';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { terbilang } from 'utils';
import { format } from 'date-fns';
interface Props {
  open: boolean;
  invoicePayment: InvoicePaymentModel;
  handleClick: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    padding: '1em'
  },
  gap: {
    rowGap: '1em'
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const PurchasePayment = ({ open, handleClick, invoicePayment }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog open={open} scroll='paper' TransitionComponent={Transition} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Grid xs={12} container justify='space-between'>
          <Typography variant='h6'>Informasi Pembayaran</Typography>
          <IconButton aria-label='close' onClick={handleClick} size='small'>
            <CloseRounded fontSize='small' />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid xs={12} container className={classes.gap}>
          <Grid xs={12} className={clsx(classes.container, classes.gap)} container>
            <Grid xs={4}>
              <Typography variant='h6'>Metode Pembayaran</Typography>
              <Typography variant='h6' color='textSecondary'>
                {invoicePayment.paymentMethod}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography variant='h6' align='center'>
                Tanggal Terbit
              </Typography>
              <Typography variant='h6' color='textSecondary' align='center'>
                {format(new Date(invoicePayment.payDate), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography variant='h6' align='right'>
                Total Pembayaran
              </Typography>
              <Typography variant='h6' color='textSecondary' align='right'>
                <NumberFormat value={invoicePayment.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} className={clsx(classes.container, classes.gap)} container>
            <Grid xs={12}>
              <Typography>No. Kwitansi</Typography>
              <Typography variant='h5'>{invoicePayment.paymentNumber}</Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12} container className={classes.gap}>
              <Grid xs={12} container>
                <Grid xs={5}>
                  <Typography>Sudah Diterima Dari</Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography variant='h6'>{invoicePayment.partnerName}</Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={5}>
                  <Typography>Terbilang</Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography variant='h6'>{terbilang(invoicePayment.totalPay) + ' Rupiah'}</Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid xs={12} container>
                <Grid xs={5}>
                  <Typography>Catatan</Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography variant='h6'>{invoicePayment.note || '-'}</Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PurchasePayment;
