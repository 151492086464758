import React, { FC, Fragment } from 'react';
import { TextField, TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import DoneIcon from '@material-ui/icons/Done';

interface Props {
  bank: BankModel;
  bankProgress: boolean;
  currentIndex: number;
  index: number;
  bankErrors: Record<string, boolean>;
  setBank: React.Dispatch<React.SetStateAction<BankModel>>;
  handleSave: () => void;
  handleCancel: () => void;
}

const BankWizard: FC<Props> = props => {
  const { bank, bankProgress, setBank, handleSave, handleCancel, bankErrors } = props;
  const { currentIndex, index } = props;

  return (
    <Fragment>
      <TableRow>
        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='bankName'
            name='bankName'
            required
            fullWidth
            label='Nama Bank'
            value={bank.bankName}
            onChange={event => setBank({ ...bank, bankName: event.target.value })}
            error={bankErrors['bankName']}
            helperText={bankErrors['bankName'] && 'Nama bank tidak boleh kosong'}
          />
        </TableCell>

        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <TextField
            id='bankCode'
            name='bankCode'
            required
            fullWidth
            label='Kode Bank '
            value={bank.bankCode}
            onChange={event => setBank({ ...bank, bankCode: event.target.value })}
            error={bankErrors['bankCode']}
            helperText={bankErrors['bankCode'] && 'Kode bank tidak boleh kosong'}
          />
        </TableCell>

        <TableCell rowSpan={2} align='center'>
          {bankProgress && index === currentIndex ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            <Fragment>
              <Tooltip title='Simpan'>
                <IconButton size='small' onClick={handleSave}>
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Batal'>
                <IconButton size='small' onClick={handleCancel}>
                  <img src={CloseCircleItem} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={2}>
          <TextField
            id='accountName'
            required
            fullWidth
            label='Nama Akun'
            value={bank.bankAccount}
            onChange={event => setBank({ ...bank, bankAccount: event.target.value })}
            error={bankErrors['bankAccount']}
            helperText={bankErrors['bankAccount'] && 'Nama akun tidak boleh kosong'}
          />
        </TableCell>

        <TableCell colSpan={2}>
          <TextField
            id='bankNumber'
            required
            fullWidth
            label='Bank Number'
            type='number'
            value={bank.bankNumber}
            error={bankErrors['bankNumber']}
            helperText={bankErrors['bankNumber'] && 'Bank Number tidak boleh kosong'}
            onChange={event => setBank({ ...bank, bankNumber: event.target.value })}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BankWizard;
