import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CreateOutlined, ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import { GREY } from 'constants/colors';
import Empty from 'images/icon/EmptyIcon.svg';
import React, { FC, useState } from 'react';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerItem: {
    rowGap: '1em',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)',
    padding: '1em 0',
    marginTop: '1em'
  },
  containerHeader: {
    padding: '0 1em'
  },
  containerBody: {
    rowGap: '1em'
  },
  wrapBody: {
    padding: '1em',
    border: `1px solid ${GREY}`,
    borderRadius: '0.5em'
  },

  collapseIcon: {
    cursor: 'pointer'
  },
  containerEmpty: {
    minHeight: '14em',
    rowGap: '1em'
  }
}));
interface Props {
  createDelevery: CreateDelevery;
  handleCollyUpdate: (index: number, action: boolean) => void;
}
export const CollyList: FC<Props> = ({ createDelevery, handleCollyUpdate }) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState<{ index: number; action: boolean }>({
    index: 0,
    action: true
  });
  const handleCollapse = (index: number) => setCollapse({ ...collapse, index: index, action: collapse.index === index ? !collapse.action : true });
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={12} container className={classes.containerHeader}>
        <Grid xs={3}>
          <Typography color='textSecondary'>NAMA KOLI</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>KETERANGAN</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography color='textSecondary'>TOTAL PRODUK</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.containerBody}>
        {createDelevery.koli.length > 0 ? (
          createDelevery.koli.map((value, index) => (
            <Grid xs={12} className={classes.wrapBody} container key={index} alignItems='center'>
              <Grid xs={12} container>
                <Grid xs={3} container alignItems='center'>
                  <Grid xs={2} container>
                    <Grid item onClick={() => handleCollapse(index)} className={classes.collapseIcon}>
                      {collapse.action && collapse.index === index ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                    </Grid>
                  </Grid>
                  <Typography>{value.name}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.packName}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.totalItem}</Typography>
                </Grid>
                <Grid xs={3} container justify='flex-end'>
                  <CreateOutlined color='primary' style={{ cursor: 'pointer' }} onClick={() => handleCollyUpdate(index, true)} />
                </Grid>
              </Grid>
              {collapse.action && collapse.index === index && (
                <Grid xs={12} container className={classes.containerItem}>
                  <Grid xs={12} container>
                    <Grid xs={3}>
                      <Typography color='textSecondary'>NO INVOICE</Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography color='textSecondary'>NAMA PRODUK</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography color='textSecondary'>KUANTITAS</Typography>
                    </Grid>
                  </Grid>
                  {value.items.map((colly, key) => (
                    <Grid xs={12} container key={key}>
                      <Grid xs={3}>
                        <Typography>{colly.InvoiceId}</Typography>
                      </Grid>
                      <Grid xs={3}>
                        <Typography>{colly.productName}</Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography>{colly.totalItem}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <Grid xs={12} container direction='column' justify='center' alignItems='center' className={classes.containerEmpty}>
            <img src={Empty} alt='Empty Logo' />
            <Typography variant='h6' color='primary'>
              Koli tidak tersedia
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
