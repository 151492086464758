import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

interface Props {
  open: boolean;
  isLoading: boolean;
  stockResource: StockAllItemModel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitStock: (ProductId: number, salesStock: number, salesStockInput: number, damageStock: number, remark: string, isStock: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '7px 0'
  }
}));

const StockDamaged: FC<Props> = props => {
  const classes = useStyles();

  const { open, setOpen, isLoading, stockResource, handleSubmitStock } = props;
  const [damageStock, setDamageStock] = useState<number>(0);
  const [remark, setRemark] = useState<string>('');

  useEffect(() => {
    setDamageStock(0);
    setRemark('');
  }, [open]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Grid xs={12} container style={{ rowGap: '1em' }}>
          <Grid xs={12}>
            <Typography variant='h5'>Edit Stok Rusak</Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              id='name produk'
              size='small'
              required
              label='Nama Produk'
              fullWidth
              value={stockResource && stockResource.productName}
              disabled
            />
          </Grid>
          <Grid xs={12} container style={{ columnGap: '1em' }} justify='space-between'>
            <Grid style={{ flex: '0.75' }}>
              <TextField
                id='category'
                size='small'
                required
                label='Kategori'
                fullWidth
                value={stockResource && stockResource.categoryName}
                disabled
              />
            </Grid>
            <Grid style={{ flex: '0.25' }}>
              <TextField
                id='warehouse'
                size='small'
                required
                label='Gudang'
                fullWidth
                value={stockResource && stockResource.warehouseName}
                disabled
              />
            </Grid>
          </Grid>

          <Grid xs={12} container style={{ columnGap: '1em' }}>
            <Grid xs={4}>
              <FormControl variant='outlined' size='small' required disabled>
                <InputLabel htmlFor='outlined-adornment-amount'>Stok Jual</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={stockResource && stockResource.salesStock}
                  autoComplete='off'
                  // error={}

                  endAdornment={<InputAdornment position='end'>{stockResource && stockResource.typeUnit}</InputAdornment>}
                  // onChange={e => setNewQty(Number(e.target.value))}
                  labelWidth={80}
                />
              </FormControl>
            </Grid>
            <Grid xs={4}>
              <FormControl variant='outlined' size='small' required>
                <InputLabel htmlFor='outlined-adornment-amount'>Stok Rusak Gudang</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={damageStock}
                  autoComplete='off'
                  error={damageStock > (stockResource && stockResource.salesStock)}
                  autoFocus
                  endAdornment={<InputAdornment position='end'>{stockResource && stockResource.typeUnit}</InputAdornment>}
                  onChange={e => setDamageStock(+e.target.value)}
                  labelWidth={155}
                />
              </FormControl>
              {damageStock > (stockResource && stockResource.salesStock) && (
                <FormHelperText error={true}>Tidak boleh melebihi stok jual</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <TextField
              size='small'
              id='edit'
              label='Alasan Perubahan'
              required
              autoComplete='off'
              value={remark}
              fullWidth
              onChange={e => setRemark(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justify='center' alignItems='center' style={{ columnGap: '1em' }}>
          <Grid xs={3}>
            <Button size='small' className={classes.button} onClick={() => setOpen(false)} color='secondary' fullWidth>
              Batal
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              size='small'
              className={classes.button}
              color='primary'
              fullWidth
              disabled={isLoading || remark === '' || damageStock > (stockResource && stockResource.salesStock)}
              onClick={() =>
                handleSubmitStock(stockResource && stockResource.id, stockResource && stockResource.salesStock, 0, damageStock, remark, false)
              }
            >
              {isLoading ? <CircularProgress size={25} color='primary' /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default StockDamaged;
