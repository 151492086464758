import React, { FC, Fragment } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Tooltip,
  TableContainer,
  CircularProgress
} from '@material-ui/core';
import { PaperCustom } from 'components';
import Skeleton from '@material-ui/lab/Skeleton';
import EditItem from 'images/icon/EditItem.svg';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import BankWizard from './BankWizard';

interface Props {
  company: CompanyModel;
  banks: BankModel[];
  isLoading: boolean;
  loadingBank: boolean;
  bankProgress: boolean;
  isUpdate: boolean;
  isCreate: boolean;
  bank: BankModel;
  currentIndex: number;
  bankErrors: Record<string, boolean>;
  setBank: React.Dispatch<React.SetStateAction<BankModel>>;
  handleSave: () => void;
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
  handleCancel: () => void;
  handleCreate: () => void;
}

const useStyles = makeStyles({
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 164,
    height: 178,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  }
});

const CompanyDetail: FC<Props> = props => {
  const classes = useStyles();
  const {
    company,
    isLoading,
    bank,
    banks,
    loadingBank,
    bankProgress,
    setBank,
    handleDelete,
    handleUpdate,
    handleSave,
    handleCancel,
    bankErrors,
    currentIndex,
    isUpdate,
    isCreate,
    handleCreate
  } = props;

  return (
    <PaperCustom>
      <Grid direction='row' container spacing={2}>
        <Grid item container spacing={2} justify={'space-between'}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Info Perusahaan</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Nama</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.name || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No Telepon</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.phoneNumber || '-'}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Website Perusahaan</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.website || '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Email Perusahaan</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.email || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No. NPWP</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.npwpNumber || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>No Handphone</TableCell>
                    <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.cellPhoneNumber || '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Alamat Perusahaan</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Alamat Perusahaan</TableCell>
                  <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kabupaten</TableCell>
                  <TableCell align='right'>
                    {isLoading ? <Skeleton variant='text' width='100%' /> : company.Kabupaten ? company.Kabupaten.name : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kode Pos</TableCell>
                  <TableCell align='right'>{isLoading ? <Skeleton variant='text' width='100%' /> : company.postalCode || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Provinsi</TableCell>
                  <TableCell align='right'>
                    {isLoading ? <Skeleton variant='text' width='100%' /> : company.Provinsi ? company.Provinsi.name : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kecamatan</TableCell>
                  <TableCell align='right'>
                    {isLoading ? <Skeleton variant='text' width='100%' /> : company.Kecamatan ? company.Kecamatan.name : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Rekening Perusahaan</Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table size='small'>
              <TableBody>
                {isCreate && (
                  <BankWizard
                    bank={bank}
                    bankProgress={bankProgress}
                    currentIndex={0}
                    bankErrors={bankErrors}
                    index={0}
                    setBank={setBank}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                  />
                )}

                {banks.length > 0 ? (
                  banks.map((value, index) =>
                    isUpdate && index === currentIndex ? (
                      <BankWizard
                        bank={bank}
                        bankProgress={bankProgress}
                        currentIndex={currentIndex}
                        bankErrors={bankErrors}
                        index={index}
                        setBank={setBank}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                      />
                    ) : (
                      <Fragment key={index}>
                        <TableRow key={index}>
                          <TableCell style={{ borderBottom: 'none' }}>Nama Bank</TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            {loadingBank ? <Skeleton variant='text' width='100%' /> : value.bankName || '-'}
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }}>Kode Bank</TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            {loadingBank ? <Skeleton variant='text' width='100%' /> : value.bankCode || '-'}
                          </TableCell>

                          <TableCell rowSpan={2} align='center'>
                            {bankProgress && index === currentIndex ? (
                              <CircularProgress size={20} color='inherit' />
                            ) : (
                              <Fragment>
                                <Tooltip title='Ubah'>
                                  <IconButton size='small' onClick={() => handleUpdate(value.id)}>
                                    <img src={EditItem} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title='Hapus'>
                                  <IconButton size='small' onClick={() => handleDelete(value.id)}>
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nama Akun</TableCell>
                          <TableCell align='right'>{loadingBank ? <Skeleton variant='text' width='100%' /> : value.bankAccount || '-'}</TableCell>
                          <TableCell>Nomor Rekening</TableCell>
                          <TableCell align='right'>{loadingBank ? <Skeleton variant='text' width='100%' /> : value.bankNumber || '-'}</TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align='center'>
                      Bank tidak tersedia
                    </TableCell>
                  </TableRow>
                )}

                {!isCreate && (
                  <TableRow>
                    <TableCell style={{ borderBottom: 'none' }} colSpan={5} align='right'>
                      <Button onClick={handleCreate}>Tambah Akun Bank</Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Logo Perusahaan</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            {company.image ? (
              <div className={classes.thumb}>
                <div className={classes.thumbInner}>
                  <img src={company.imageUrl ? company.imageUrl : ''} className={classes.img} />
                </div>
              </div>
            ) : (
              <Typography variant='subtitle1'>Tidak ada logo</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default CompanyDetail;
