import React, { FC, useContext } from 'react';
import { List, makeStyles } from '@material-ui/core';
import { mdiCubeOutline, mdiAccountMultipleOutline, mdiLogout, mdiTruckDeliveryOutline, mdiCurrencyUsd, mdiFileChartOutline } from '@mdi/js';
import { WHITE } from 'constants/colors';
import DrawerItem from './DrawerItem';
import SettingsIcon from '@material-ui/icons/Settings';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { hasAccess } from 'selectors';
import TypeUser from 'typings/enum/TypeUser';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRole from 'hooks/useRole';
import { modules } from 'constants/modules';

interface Props {
  currentUserData: CurrentUser | undefined;
  handleDrawerClose(): void;
}

const useStyles = makeStyles({
  textIcon: {
    color: WHITE,
    fontSize: 50,
    fontWeight: 'bold'
  }
});

const DrawerList: FC<Props> = props => {
  const classes = useStyles();
  const { handleDrawerClose, currentUserData } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdminReturn = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN_RETURN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isSupervisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isDriver = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.DRIVER]
  });

  const products = [
    { name: 'Produk', path: '/produk' },
    { name: 'Kategori', path: '/kategori' },
    { name: 'Stok', path: '/semuastok' }
  ];

  const costs = [
    { name: 'Operasional', path: '/operasional' },
    { name: 'Komisi Kurir', path: '/komisi' }
  ];
  const orders = [
    { name: 'Penjualan', path: '/penjualan' },
    { name: 'Pembelian', path: '/purchase' }
  ];

  const returns = [{ name: 'Return Penjualan', path: '/return-penjualan' }];

  const invoices = [{ name: 'Invoice Penjualan', path: '/invoice' }];

  if (isSuperAdmin) {
    invoices.push({ name: 'Invoice Pembelian', path: '/invoice-pembelian' });
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }
  if (isAdmin) {
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  if (isAdminReturn) {
    returns.unshift({ name: 'Barang Rusak', path: '/return-barang-rusak' });
    returns.push({ name: 'Return Pembelian', path: '/return-pembelian' });
  }

  return (
    <List className={classes.textIcon}>
      {hasAccess(currentUserData, modules.PARTNER) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiAccountMultipleOutline} path='/mitra' label='Mitra' child={false} />
      )}

      {(hasAccess(currentUserData, modules.PRODUCT) ||
        hasAccess(currentUserData, modules.CATEGORIES) ||
        hasAccess(currentUserData, modules.STOCK)) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          iconMdi={mdiCubeOutline}
          path='#'
          label='Produk & Stok'
          child={true}
          childData={products.filter(product => {
            if (product.name === 'Produk') {
              return hasAccess(currentUserData, modules.PRODUCT);
            }

            if (product.name === 'Kategori' && !!currentUserData && currentUserData.type !== TypeUser.SALES) {
              return hasAccess(currentUserData, modules.CATEGORIES);
            }
            if (product.name === 'Stok') {
              return hasAccess(currentUserData, modules.STOCK);
            }
          })}
        />
      )}

      {(hasAccess(currentUserData, modules.PURCHASE_ORDER) ||
        hasAccess(currentUserData, modules.SALES_ORDER) ||
        hasAccess(currentUserData, modules.HOLDING_RETURN) ||
        hasAccess(currentUserData, modules.DELIVERY)) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={ShoppingCartIcon}
          path='#'
          label='Order'
          child={true}
          childData={orders.filter(order => {
            switch (order.name) {
              case 'Penjualan':
                return hasAccess(currentUserData, modules.SALES_ORDER);
              case 'Pembelian':
                return hasAccess(currentUserData, modules.PURCHASE_ORDER);
            }
          })}
        />
      )}

      {(isSuperAdmin || isSupervisor || isAdmin) && (
        <DrawerItem
          handleDrawerClose={handleDrawerClose}
          Icon={InvoiceIcon}
          path='#'
          label='Invoice'
          child={true}
          childData={invoices.filter(invoice => {
            switch (invoice.name) {
              case 'Invoice Penjualan':
                return hasAccess(currentUserData, modules.INVOICE);
              case 'Invoice Pembelian':
                return !isAdmin && hasAccess(currentUserData, modules.PURCHASE_INVOICE);
            }
          })}
        />
      )}

      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiTruckDeliveryOutline} path='/surat-jalan' label='Surat Jalan' child={false} />

      <DrawerItem
        handleDrawerClose={handleDrawerClose}
        iconMdi={mdiCurrencyUsd}
        path='#'
        label='Biaya'
        child={true}
        childData={costs.filter(cost => {
          switch (cost.name) {
            case 'Operasional':
              return hasAccess(currentUserData, modules.OPERATIONAL);
            case 'Komisi Kurir':
              return hasAccess(currentUserData, modules.OPERATIONAL);
          }
        })}
      />

      {isSuperAdmin && (
        <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiFileChartOutline} path='/laporan' label='Laporan' child={false} />
      )}
      {(!isAdmin && hasAccess(currentUserData, modules.USERS)) ||
      (!isAdmin && hasAccess(currentUserData, modules.COMPANY)) ||
      (!isAdmin && hasAccess(currentUserData, modules.WAREHOUSE)) ||
      (!isAdmin && hasAccess(currentUserData, modules.ZONE))
        ? !!currentUserData &&
          currentUserData.type !== TypeUser.SALES && (
            <DrawerItem handleDrawerClose={handleDrawerClose} Icon={SettingsIcon} path='/pengaturan' label='Pengaturan' child={false} />
          )
        : ''}
      {!isAdmin && hasAccess(currentUserData, modules.APPLOG) && (
        <DrawerItem handleDrawerClose={handleDrawerClose} Icon={ExitToAppIcon} path='/appLog' label='App Log' child={false}></DrawerItem>
      )}
      <DrawerItem handleDrawerClose={handleDrawerClose} iconMdi={mdiLogout} path='logout' label='Keluar' child={false} />
    </List>
  );
};

export default DrawerList;
