import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, LinearProgress, makeStyles, Tab, Tabs, TextField, Theme, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';

import { a11yProps, Breadcrumb, Page, PaperCustom, TabPanel } from 'components';
import { GREEN, WHITE } from 'constants/colors';
import {
  COURIER_URL,
  DELIVERY_ORDER_BASE_URL,
  DELIVERY_ORDER_GET_INVOICE,
  DELIVERY_ORDER_GET_PRODUCT,
  DELIVERY_ORDER_PRINT,
  PARTNER_BASE_URL
} from 'constants/url';
import { format } from 'date-fns';
import useRouter from 'hooks/useRouter';
import { dummyCreateDelevery } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import ModalInfo from './ModalInfo';
import ModalSuccess from './ModalSuccess';
import ProductsList from './ProductsList';
import { CollyList } from './CollyList';
import CollyUpdate from './CollyUpdate';
import FlexBox from 'components/FlexBox';
import PartnerType from 'typings/enum/PartnerType';

const useStyles = makeStyles((theme: Theme) => ({
  containerTop: {
    display: 'flex',
    columnGap: '1em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN,
      transform: 'scale(1.1)'
    }
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));

interface DataType {
  isLoading: boolean;
  data: { id: number; name: string; invoiceNumber: string; partnerType: string; customerReseller: { id: number; name: string }[] }[];
}
interface DataTypeCourier {
  isLoading: boolean;
  data: { id: number; firstName: string }[];
}
export interface InvoiceItems {
  isLoading: boolean;
  data: {
    id: number;
    invoiceNumber: string;
    productName: string;
    totalItem: number;
    InvoiceId: number;
    ProductId: number;
    totalItemBefore: number;
    totalBag: number;
  }[];
}
const dummyData = {
  isLoading: false,
  data: []
};

const dummyCollyInput = {
  name: '',
  packName: ''
};
const DeliveryOrdersCreate = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const [createDelevery, setCreateDelevery] = useState<CreateDelevery>(dummyCreateDelevery);
  const [createDeleveryUpdate, setCreateDeleveryUpdate] = useState<CollyType[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isInfo, setIsInfo] = useState<boolean>(false);
  const [partners, setPartners] = useState<DataType>(dummyData);
  const [courierResource, setCourierResource] = useState<DataTypeCourier>(dummyData);
  const [invoices, setInvoices] = useState<DataType>(dummyData);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItems>(dummyData);
  const [collyInput, setCollyInput] = useState<{
    name: string;
    packName: string;
  }>(dummyCollyInput);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState<boolean>(false);
  const [deliveryId, setDeliveryId] = useState<number>(0);
  const [collyUpdate, setCollyUpdate] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: 0 });
  const [tab, setTab] = useState<number>(0);

  const disabled = Object.values(createDelevery).some(
    val => (typeof val === 'number' && val === 0) || (Array.isArray(val) && val.length < 1) || (typeof val === 'string' && val === '')
  );

  const handleCloseModalInfo = () => setIsInfo(false);

  const handleSearchPartner = async () => {
    setPartners({ ...partners, isLoading: true });
    const params = new URLSearchParams();
    params.append('partnerType', 'CUSTOMER');
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners({ ...partners, isLoading: false, data: data.data });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSearchCourier = async () => {
    setCourierResource({ ...courierResource, isLoading: true });
    try {
      const { data } = await axios.get(`${COURIER_URL}`);

      setCourierResource({ ...courierResource, isLoading: false, data: data.data });
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleFetchInvoice = useCallback(async () => {
    setInvoices({ ...invoices, isLoading: true });
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_GET_INVOICE(createDelevery.PartnerId)}`);
      setInvoices({ ...invoices, isLoading: false, data: data });
    } catch (error) {
      console.log('error :', error);
    }
  }, [createDelevery.PartnerId]);

  const fecthInvoiceItem = useCallback(async () => {
    const params = new URLSearchParams();
    setInvoiceItems({ ...invoiceItems, isLoading: true });
    params.append('invoiceId', createDelevery.invoice.map(value => value.InvoiceId).join(','));
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_GET_PRODUCT}?${params.toString()}`);
      setInvoiceItems({
        ...invoiceItems,
        isLoading: false,
        data: data.map((val: any) => ({ ...val, totalItem: +val.totalItem, totalItemBefore: +val.totalItem, totalBag: 0 }))
      });
    } catch (error) {
      console.log('error', error);
      setInvoiceItems({ ...invoiceItems, isLoading: false, data: [] });
    }
  }, [createDelevery.invoice]);

  const handleCreate = async () => {
    setIsLoadingCreate(true);
    try {
      const { data } = await axios.post(DELIVERY_ORDER_BASE_URL, createDelevery);
      setDeliveryId(data.data.id);
      handleCloseModalInfo();
      setIsLoadingCreate(false);
      setIsSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCounterItem = (id: number, action: string = 'increase') =>
    setInvoiceItems({
      ...invoiceItems,
      data: invoiceItems.data.map((val, key) =>
        val.id === id ? { ...val, totalItem: action.includes('increase') ? val.totalItem + 1 : val.totalItem - 1 } : val
      )
    });

  const handleChangeItem = (id: number, qty: number) =>
    setInvoiceItems({
      ...invoiceItems,
      data: invoiceItems.data.map((val, key) => (val.id === id ? { ...val, totalItem: qty } : val))
    });

  const deliveryPrint = async () => {
    setIsLoadingPrint(true);
    try {
      const { data } = await axios.get(DELIVERY_ORDER_PRINT(deliveryId), { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      setIsLoadingPrint(false);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      handleSkip();
    } catch (error) {
      console.log('error :', error);
    }
  };

  const getTotalBag = (id: number) => {
    const totalBag = createDelevery.koli.map(val =>
      val.items.filter(value => value.ProductId === id).reduce((previousValue, currentValue) => previousValue + currentValue.totalItem, 0)
    );
    const total = totalBag.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return total;
  };

  const validation = invoiceItems.data.some(val => getTotalBag(val.ProductId) < val.totalItemBefore);
  const handleSave = () => {
    const tempItem = invoiceItems.data
      .filter(value => value.totalItem > 0 && value.totalItem > value.totalBag && getTotalBag(value.ProductId) < value.totalItemBefore)
      .map(val => ({
        InvoiceId: val.InvoiceId,
        productName: val.productName,
        ProductId: val.ProductId,
        totalItem: val.totalItem
      }));

    const totalItem = invoiceItems.data
      .filter(value => value.totalItem > value.totalBag && getTotalBag(value.ProductId) < value.totalItemBefore)
      .map(val => val.totalItem)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    if (createDelevery.koli.some(val => val.name.toLowerCase().includes(collyInput.name.toLowerCase()))) {
      setCreateDelevery({
        ...createDelevery,
        koli: createDelevery.koli.map(value => ({
          ...value,
          totalItem: value.totalItem + totalItem,
          items: value.name.toLowerCase().includes(collyInput.name.toLowerCase())
            ? value.items.some(value => tempItem.some(val => val.ProductId === value.ProductId))
              ? value.items.map(value => ({
                  ...value,
                  totalItem:
                    value.totalItem + tempItem.filter(val => val.ProductId === value.ProductId).reduce((prev, current) => prev + current.totalItem, 0)
                }))
              : value.items.concat(tempItem)
            : value.items
        }))
      });
    } else {
      setCreateDelevery({
        ...createDelevery,
        packingBy: (currentUser && currentUser.id) || 0,
        koli:
          tempItem.length > 0
            ? createDelevery.koli.concat([
                {
                  ...collyInput,
                  totalItem: totalItem,
                  items: tempItem
                }
              ])
            : createDelevery.koli
      });
    }

    setInvoiceItems({
      ...invoiceItems,
      data: invoiceItems.data.map(val => ({
        ...val,
        totalItem: val.totalItemBefore - (val.totalItem + getTotalBag(val.ProductId))
      }))
    });
    setCollyInput(dummyCollyInput);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => setTab(newValue);

  const handleSkip = () => history.push('/surat-jalan');
  useEffect(() => {
    handleSearchPartner();
    handleSearchCourier();
  }, []);

  useEffect(() => {
    handleFetchInvoice();
  }, [handleFetchInvoice]);

  useEffect(() => {
    if (createDelevery.PartnerId < 1) return;
    fecthInvoiceItem();
  }, [fecthInvoiceItem]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCreateDelevery({ ...createDelevery, [event.target.id]: event.target.value });

  const handleChangeColly = (event: React.ChangeEvent<HTMLInputElement>) => setCollyInput({ ...collyInput, [event.target.id]: event.target.value });

  const handleSaveUpdate = () => {
    const tempData = [...createDelevery.koli.filter((val, index) => index !== collyUpdate.index), ...createDeleveryUpdate];

    setCreateDelevery({
      ...createDelevery,
      koli:
        tempData.length > 0
          ? tempData.filter(val => val.totalItem > 0).map(value => ({ ...value, items: value.items.filter(value => value.totalItem > 0) }))
          : []
    });

    const getTotalItem = invoiceItems.data.map(value => {
      return {
        ...value,
        totalItem: getTotalUpdate(value.ProductId, value.InvoiceId) > -1 ? getTotalUpdate(value.ProductId, value.InvoiceId) : value.totalItem
      };
    });

    setInvoiceItems({ ...invoiceItems, data: getTotalItem });
    setCollyUpdate({ ...collyUpdate, isOpen: false });
  };

  const getTotalUpdate = (productId: number, invoiceId: number) => {
    const tempData = createDeleveryUpdate[0].items.filter(value => value.ProductId === productId && value.InvoiceId === invoiceId);
    return tempData.length > 0 ? tempData[0].totalItem : -1;
  };

  const handleSubmit = () => setIsInfo(true);

  const getHelperText = () => 'Pilih Mitra untuk memilih Invoice Rujukan';

  const handleCollyUpdate = (index: number, action: boolean) => {
    setCollyUpdate({ ...collyUpdate, index: index, isOpen: action ? true : false });
    const tempData = {
      ...createDelevery.koli[index],
      items: createDelevery.koli[index].items.map(value => ({ ...value, totalItemBefore: value.totalItem }))
    };

    setCreateDeleveryUpdate(action ? [tempData] : []);
  };
  return (
    <Page title='Surat Jalan Create'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Surat Jalan
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container style={{ rowGap: '1em' }}>
            <Grid xs={12} container alignItems='center' justify='space-between'>
              <Typography variant='h5'>Buat Surat Jalan Baru</Typography>
              <Button variant='contained' color='primary' onClick={handleSubmit} disabled={disabled || validation}>
                Simpan Surat Jalan
              </Button>
            </Grid>
            <Grid xs={12} className={classes.containerTop}>
              <Grid xs={6}>
                <TextField
                  id='deliveryNumber'
                  autoComplete='off'
                  required
                  fullWidth
                  label='No Surat Jalan'
                  value={createDelevery.deliveryNumber}
                  onChange={handleChange}
                />
                <FlexBox columnGap={1}>
                  <Grid xs={8}>
                    <Autocomplete
                      onChange={(event, value) =>
                        setCreateDelevery({ ...createDelevery, PartnerId: value ? +partners.data.filter(val => val.name === value)[0].id : 0 })
                      }
                      loading={partners.isLoading}
                      loadingText={<LinearProgress />}
                      disableListWrap
                      options={partners.data.map(val => val.name)}
                      PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                      renderInput={params => <TextField {...params} required label='Pilih Mitra' variant='outlined' />}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <TextField
                      id='partnerType'
                      required
                      fullWidth
                      label='Tipe Mitra'
                      placeholder='Tipe Mitra'
                      value={
                        partners.data.filter(val => val.id === createDelevery.PartnerId).length > 0
                          ? partners.data.filter(val => val.id === createDelevery.PartnerId)[0].partnerType
                          : ''
                      }
                      disabled
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </FlexBox>

                <Grid xs={12}>
                  <Autocomplete
                    onChange={(event, value) =>
                      setCreateDelevery({
                        ...createDelevery,
                        kurirId: value ? +courierResource.data.filter(val => val.firstName === value)[0].id : 0
                      })
                    }
                    loading={courierResource.isLoading}
                    loadingText={<LinearProgress />}
                    disableListWrap
                    options={courierResource.data.map(val => val.firstName)}
                    PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                    renderInput={params => <TextField {...params} required label='Pilih Kurir' variant='outlined' />}
                  />
                </Grid>
              </Grid>
              <Grid xs={6}>
                <TextField
                  id='deliveryDate'
                  required
                  fullWidth
                  type='date'
                  label='Tanggal Surat Jalan'
                  value={createDelevery.deliveryDate && format(createDelevery.deliveryDate, 'yyyy-MM-dd')}
                  onChange={e => setCreateDelevery({ ...createDelevery, deliveryDate: new Date(e.target.value) })}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <Autocomplete
                  multiple
                  loading={invoices.isLoading}
                  loadingText={<LinearProgress />}
                  onChange={(event, value) => {
                    const getData = invoices.data
                      .filter(invoice => value.some(val => val.includes(invoice.invoiceNumber)))
                      .map(val => ({
                        InvoiceId: val.id,
                        invoiceNumber: val.invoiceNumber
                      }));

                    setCreateDelevery({ ...createDelevery, invoice: getData, koli: [] });
                  }}
                  disableListWrap
                  options={invoices.data.map(val => val.invoiceNumber)}
                  PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                  renderInput={params => (
                    <TextField {...params} required label='Pilih Invoice Rujukan' helperText={getHelperText()} variant='outlined' />
                  )}
                />
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>

            <Grid xs={12}>
              <Typography variant='h6'>List semua produk dan koli</Typography>
              <Tabs value={tab} onChange={handleChangeTab} aria-label='Tab Delevery' indicatorColor='primary'>
                <Tab label='Semua Produk' {...a11yProps(0)} />
                <Tab label='Semua Koli' {...a11yProps(1)} />
              </Tabs>
            </Grid>

            <Grid xs={12}>
              <TabPanel value={tab} index={0}>
                <ProductsList
                  invoiceItems={invoiceItems}
                  setCreateDelevery={setCreateDelevery}
                  handleCounterItem={handleCounterItem}
                  collyInput={collyInput}
                  getTotalBag={getTotalBag}
                  handleChange={handleChangeColly}
                  createDelevery={createDelevery}
                  handleChangeItem={handleChangeItem}
                  handleSave={handleSave}
                  setCollyInput={setCollyInput}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <CollyList createDelevery={createDelevery} handleCollyUpdate={handleCollyUpdate} />
              </TabPanel>
            </Grid>
          </Grid>
          <ModalSuccess open={isSuccess} deliveryPrint={deliveryPrint} isLoadingPrint={isLoadingPrint} handleSkip={handleSkip} />
          <ModalInfo open={isInfo} isLoading={isLoadingCreate} handleCloseModalInfo={handleCloseModalInfo} handleCreate={handleCreate} />
          <CollyUpdate
            open={collyUpdate.isOpen}
            createDelevery={createDeleveryUpdate[0]}
            handleCollyUpdate={handleCollyUpdate}
            handleSaveUpdate={handleSaveUpdate}
            setCreateDeleveryUpdate={setCreateDeleveryUpdate}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrdersCreate;
