import { Button, FormHelperText, Grid, IconButton, InputBase, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { AddOutlined, InfoOutlined, RemoveOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { GREY, PRIMARY, PRIMARY_SECONDARY, RED, WHITE, YELLOW } from 'constants/colors';
import Empty from 'images/icon/EmptyIcon.svg';
import React, { FC, useEffect, useState } from 'react';
import { InvoiceItems } from '.';
const useStyles = makeStyles((theme: Theme) => ({
  iconWarning: {
    color: YELLOW,
    paddingRight: '0.2em'
  },
  container: {
    rowGap: '1em'
  },
  containerInput: {
    display: 'flex',
    columnGap: '1em'
  },
  containerHeader: {
    padding: '0 1em'
  },
  containerBody: {
    rowGap: '1em'
  },
  containerBox: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.4em'
  },
  containerOption: {
    padding: '0.7em 1em',
    columnGap: '1em',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: GREY
    }
  },
  wrapBody: {
    padding: '1em',
    border: `1px solid ${GREY}`,
    borderRadius: '0.5em'
  },
  inputContainer: {
    borderRadius: '0.3em',
    border: `1px solid ${PRIMARY}`
  },
  iconButton: {
    background: PRIMARY,
    '&:hover': {
      background: PRIMARY_SECONDARY
    }
  },
  icon: {
    color: WHITE
  },
  button: {
    borderRadius: '1em'
  },
  containerEmpty: {
    minHeight: '15em',
    rowGap: '1em'
  },
  error: {
    fontSize: '.9rem'
  }
}));
interface Props {
  invoiceItems: InvoiceItems;
  createDelevery: CreateDelevery;
  setCreateDelevery: React.Dispatch<React.SetStateAction<CreateDelevery>>;
  handleCounterItem: (id: number, action?: string) => void;
  handleChangeItem: (id: number, qty: number) => void;
  getTotalBag: (id: number) => number;
  handleSave: () => void;
  collyInput: { name: string; packName: string };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCollyInput: React.Dispatch<React.SetStateAction<{ name: string; packName: string }>>;
}
const ProductsList: FC<Props> = ({
  invoiceItems,
  handleCounterItem,
  handleSave,
  getTotalBag,
  handleChangeItem,
  collyInput,
  handleChange,
  setCollyInput,
  createDelevery
}) => {
  const classes = useStyles();
  const [isSugestion, setSugestion] = useState<{ isShow: boolean; id: number }>({ isShow: false, id: 0 });

  const disable =
    invoiceItems.data
      .filter(val => val.totalItem > 0)
      .some(val => val.totalItem < 0 || val.totalItem > val.totalItemBefore - getTotalBag(val.ProductId)) ||
    invoiceItems.data.filter(val => val.totalItem > 0).length < 1 ||
    Object.values(collyInput).some(val => val === '');

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => setSugestion({ ...isSugestion, isShow: 1 === 1 });

  const handleBlur = () => setTimeout(() => setSugestion({ ...isSugestion, isShow: 1 !== 1 }), 500);

  const handleSugestion = (index: number) => {
    setCollyInput({ ...collyInput, name: createDelevery.koli[index].name, packName: createDelevery.koli[index].packName });
  };

  useEffect(() => {
    return () => {
      setSugestion({ ...isSugestion, isShow: createDelevery.koli.some(val => val.name.toLowerCase().includes(collyInput.name.toLowerCase())) });
    };
  }, [collyInput.name]);

  return (
    <Grid xs={12} container className={classes.container}>
      <Typography variant='h6'>Semua Produk</Typography>
      <Grid xs={12} container alignItems='center'>
        <InfoOutlined className={classes.iconWarning} fontSize='default' />
        <Typography>Masukkan nama koli dan keterangan lalu input qty untuk membuat koli</Typography>
      </Grid>
      <Grid xs={12} className={classes.containerInput}>
        <Grid xs={6} style={{ position: 'relative' }}>
          <TextField
            fullWidth
            required
            id='name'
            autoComplete='off'
            value={collyInput.name}
            label='Nama Koli'
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {createDelevery.koli.length > 0 && isSugestion.isShow && (
            <Grid className={classes.containerBox}>
              {createDelevery.koli.map((colly, index) => (
                <Grid xs={12} className={classes.containerOption} container key={index} onClick={() => handleSugestion(index)}>
                  <Typography variant='h6'>{colly.name}</Typography>
                  &#8594;<Typography color='textSecondary'>{colly.packName}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid xs={6}>
          <TextField fullWidth required id='packName' autoComplete='off' value={collyInput.packName} label='Keterangan' onChange={handleChange} />
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.containerHeader}>
        <Grid xs={3}>
          <Typography color='textSecondary'>NO INVOICE</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>NAMA PRODUK</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography color='textSecondary'>KUANTITAS</Typography>
        </Grid>
      </Grid>

      <Grid xs={12} container className={classes.containerBody}>
        {invoiceItems.isLoading ? (
          <Grid xs={12}>
            {[...Array(5)].map(val => (
              <Skeleton height={70} key={val} />
            ))}
          </Grid>
        ) : invoiceItems.data.filter(val => getTotalBag(val.ProductId) < val.totalItemBefore).length > 0 ? (
          invoiceItems.data
            .filter(val => getTotalBag(val.ProductId) < val.totalItemBefore)
            .map((value, index) => (
              <Grid xs={12} className={classes.wrapBody} container key={index} alignItems='center'>
                <Grid xs={3}>
                  <Typography>{value.invoiceNumber}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.productName}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.totalItemBefore - getTotalBag(value.ProductId)}</Typography>
                </Grid>
                <Grid xs={3} container justify='space-between' alignItems='center'>
                  <IconButton aria-label='remove' component='div' size='small' className={classes.iconButton} disabled={value.totalItem < 1}>
                    <RemoveOutlined className={classes.icon} onClick={() => handleCounterItem(value.id, 'decrease')} />
                  </IconButton>

                  <Grid
                    className={classes.inputContainer}
                    style={{
                      border:
                        value.totalItem < 0 || value.totalItem + getTotalBag(value.ProductId) > value.totalItemBefore
                          ? '1px solid ' + RED
                          : '1px solid ' + PRIMARY
                    }}
                  >
                    <InputBase
                      value={value.totalItem}
                      inputProps={{ min: 0, style: { textAlign: 'center' }, inputMode: 'numeric' }}
                      onChange={e => handleChangeItem(value.id, +e.target.value)}
                    />
                  </Grid>

                  <IconButton
                    aria-label='add'
                    disableFocusRipple={false}
                    disableRipple={true}
                    component='div'
                    disabled={value.totalItem + getTotalBag(value.ProductId) >= value.totalItemBefore}
                    size='small'
                    className={classes.iconButton}
                  >
                    <AddOutlined className={classes.icon} onClick={() => handleCounterItem(value.id)} />
                  </IconButton>

                  {(value.totalItem < 0 || value.totalItem + getTotalBag(value.ProductId) > value.totalItemBefore) && (
                    <Grid xs={12} container justify='center'>
                      <FormHelperText error={true}>
                        <Typography align='center' className={classes.error}>
                          Harus lebih besar dari 0 dan tidak boleh melebehi Qty Awal ({value.totalItemBefore - getTotalBag(value.ProductId)})
                        </Typography>
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))
        ) : (
          <Grid xs={12} container direction='column' justify='center' alignItems='center' className={classes.containerEmpty}>
            <img src={Empty} alt='Empty Logo' />
            <Typography variant='h6' color='primary'>
              Product tidak tersedia
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid xs={12}>
        <Button variant='contained' color='primary' fullWidth onClick={handleSave} className={classes.button} disabled={disable}>
          Tambah Koli Baru
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductsList;
