import { Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandLessOutlined, ExpandLessRounded, ExpandMoreOutlined, ExpandMoreRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { GREY } from 'constants/colors';
import React, { FC, useState } from 'react';
interface Props {
  isLoading: boolean;
  deliveryOrderItem: DeliveryOrderItem[];
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerHeader: {
    padding: '0 1em'
  },
  containerBody: {
    rowGap: '1em'
  },
  containerItem: {
    rowGap: '1em',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)',
    padding: '1em 0',
    marginTop: '1em'
  },
  collapseIcon: {
    cursor: 'pointer'
  },
  wrapBody: {
    padding: '1em',
    border: `1px solid ${GREY}`,
    borderRadius: '0.5em'
  },
  expandAnimation: {
    animation: `$expandEffect 300ms linear`
  },
  '@keyframes expandEffect': {
    '0%': {
      transform: 'scaleX(0)'
    },
    '50%': {
      transform: 'scaleX(0.5)'
    },
    '100%': {
      transform: 'scaleX(1)'
    }
  }
}));
const DeliveryOrderList: FC<Props> = ({ isLoading, deliveryOrderItem }) => {
  const classes = useStyles();

  const [collapse, setCollapse] = useState<{ index: number; action: boolean }>({
    index: 0,
    action: true
  });
  const handleCollapse = (index: number) => setCollapse({ ...collapse, index: index, action: collapse.index === index ? !collapse.action : true });

  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={12} container className={classes.containerHeader}>
        <Grid xs={3}>
          <Typography color='textSecondary'>NAMA KOLI</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>KETERANGAN</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography color='textSecondary'>TOTAL PRODUK</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} container className={classes.containerBody}>
        {isLoading ? (
          <Grid xs={12}>
            {[...Array(5)].map(val => (
              <Skeleton height={70} key={val} />
            ))}
          </Grid>
        ) : (
          deliveryOrderItem.map((value, index) => (
            <Grid xs={12} className={classes.wrapBody} container key={index} alignItems='center'>
              <Grid xs={12} container>
                <Grid xs={3} container alignItems='center'>
                  <Grid xs={2}>
                    <Grid item onClick={() => handleCollapse(index)} className={classes.collapseIcon}>
                      {collapse.action && collapse.index === index ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                    </Grid>
                  </Grid>
                  <Typography>{deliveryOrderItem[index].BagItem[0].InvoiceNumber}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.name}</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography>{value.packName}</Typography>
                </Grid>
              </Grid>
              {collapse.action && collapse.index === index && (
                <Grid xs={12} container className={classes.containerItem}>
                  <Grid xs={12} container>
                    <Grid xs={3}>
                      <Typography color='textSecondary'>NO INVOICE</Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography color='textSecondary'>NAMA PRODUK</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography color='textSecondary'>KUANTITAS</Typography>
                    </Grid>
                  </Grid>
                  {value.BagItem.map((colly, key) => (
                    <Grid xs={12} container>
                      <Grid xs={3}>
                        <Typography>{colly.InvoiceNumber}</Typography>
                      </Grid>
                      <Grid xs={3}>
                        <Typography>{colly.productName}</Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography>{colly.totalItem}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default DeliveryOrderList;
