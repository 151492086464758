import React, { FC, Fragment, useContext } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  Chip,
  Grid,
  LinearProgress,
  CardMedia
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { RED, BLACK, GREEN, PRIMARY, BLUE } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusOrder, TableRowCollapse } from 'components';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { DeleteOutline } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ItemStatus from 'typings/enum/ItemStatus';
import useRouter from 'hooks/useRouter';
import TypeUser from 'typings/enum/TypeUser';
import HeaderRow from './HeaderRow';
import BodyItem from './BodyItem';
import clsx from 'clsx';
import { formatDate } from 'utils/formatDate';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';

interface Props {
  salesOrder: SalesOrderModel;
  isLoading: boolean;
  handleOpenSO: (id: number) => void;
  onDelete: React.MouseEventHandler;
  openCollapse: boolean;
  handleOpenCollapse: React.MouseEventHandler;
  index: number;
  handleOpenImage: (imagePath: string) => void;
  indexCollapse: number;
  loadingItem: boolean;
  isAccess: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },

    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    link: {
      color: BLUE,
      cursor: 'pointer'
    },
    pending: {
      color: BLACK
    },
    reject: {
      color: RED
    },
    confirm: {
      color: PRIMARY
    },
    success: {
      color: GREEN
    },
    containerHeader: {
      margin: theme.spacing(1),
      display: 'flex'
    },
    bodyContainer: {
      borderRadius: '5px',
      marginBottom: theme.spacing(2),

      padding: theme.spacing(1)
    }
  })
);

const BodyRow: FC<Props> = props => {
  const {
    salesOrder,
    isLoading,
    handleOpenImage,
    onDelete,
    handleOpenSO,
    openCollapse,
    indexCollapse,
    handleOpenCollapse,
    index,
    loadingItem,
    isAccess
  } = props;
  const classes = useStyles();

  const { history } = useRouter();

  const handleLink = (id: number) => {
    if (isAccess === TypeUser.SUPERADMIN || isAccess === TypeUser.ADMIN) {
      window.open(`/penjualan/${id}`);
    } else {
      handleOpenSO(salesOrder.id);
      history.push(`/penjualan/${id}`);
    }
  };

  const handleLinkInvoice = (id: number) => {
    window.open(`/invoice/${id}`, '_blank');
  };

  const handleStatus = (item: SalesOrderItemModel) => {
    if (item.status === ItemStatus.CONFIRM) {
      return (
        <Typography variant='body2' className={classes.confirm}>
          Disetujui
        </Typography>
      );
    } else if (item.status === ItemStatus.REJECT) {
      return (
        <Typography variant='body2' className={classes.reject}>
          Ditolak
        </Typography>
      );
    } else if (item.status === ItemStatus.HAS_INVOICE) {
      return (
        <Typography variant='body2' className={classes.success}>
          Invoice Telah <br /> Dibuat
        </Typography>
      );
    } else {
      return (
        <Typography variant='body2' className={classes.pending}>
          Menunggu
        </Typography>
      );
    }
  };

  return (
    <>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCellStart>

        <TableCellMiddle onClick={() => handleLink(salesOrder.id)}>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={clsx(classes.customerName, classes.link)}>
              {'#' + salesOrder.orderNumber}
            </Typography>
          )}
        </TableCellMiddle>
        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : formatDate(salesOrder.orderDate)}</TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {salesOrder.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {salesOrder.SalesName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={salesOrder.totalPrice || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : <StatusOrder status={salesOrder.statusOrder} />}
        </TableCellMiddle>

        <TableCellEnd
          align='center'
          style={{
            borderRight: isAccess === TypeUser.ADMIN ? (salesOrder.isAccept ? '1px solid lightgrey' : '1px solid #E4AE42') : '1px solid lightgrey'
          }}
        >
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            salesOrder.statusOrder.includes(SalesOrderStatus.PENDING) && (
              <Fragment>
                <Tooltip title='Hapus '>
                  <IconButton size='small' onClick={onDelete}>
                    <DeleteOutline fontSize='small' color='error' />
                  </IconButton>
                </Tooltip>
              </Fragment>
            )
          )}
        </TableCellEnd>
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableBody>
            {salesOrder.invoice &&
              salesOrder.invoice.map((value, index) => (
                <TableRow key={index}>
                  <TableCell>
                    Nomor Invoice {index + 1}: &nbsp; {value.statusPayment === 'CANCEL' && <Chip label='Dibatalkan' disabled />}
                  </TableCell>
                  <TableCell colSpan={8} align='right'>
                    <Button onClick={() => handleLinkInvoice(value.id)} variant='text'>
                      {value.invoiceNumber || '-'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell> Nama </TableCell>
              <TableCell align='center'>Kuantitas</TableCell>
              <TableCell align='center'>Gudang</TableCell>
              <TableCell align='right'> Total Harga </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              salesOrder.SalesOrderItem &&
              salesOrder.SalesOrderItem.map((value: SalesOrderItemModel, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{value.productName || '-'}</TableCell>
                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.totalItem + ' ' + value.typeUnit}
                  </TableCell>
                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.warehouse || '-'}
                  </TableCell>

                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </>
  );
};

export default BodyRow;
