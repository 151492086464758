import React, { FC, useEffect, useState, useContext } from 'react';
import { Theme, Grid, Container, Typography, makeStyles, Button, Tooltip, Badge, CircularProgress } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRole from 'hooks/useRole';
import Pagination from '@material-ui/lab/Pagination';
import useDebounced from 'hooks/useDebounced';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import { INVOICE_BASE_URL, ZONE_BASE_URL, EXPORT_INVOICE_BASE_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import DateRangeFilter from 'components/DateRangeFilter';
import InvoiceTable from './components/InvoiceTable';
import { GREEN, WHITE, PRIMARY, PRIMARY_SECONDARY } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import TypeUser from 'typings/enum/TypeUser';
import ModalSuccess from './components/ModalSuccess';
import ModalConfirmation from './components/ModalConfirmation';
import { PublishRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  spaceTop: {
    paddingBottom: 30
  },
  fourthGrid: {
    marginTop: 20
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  paper: {
    padding: '20px',
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  iconCard: {
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  icon: {
    paddingTop: 5,
    marginTop: 5,
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  gridPaper: {
    paddingTop: '24px'
  }
}));

const InvoicePage: FC = () => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [statusPayment, setStatusPayment] = useState<string>('UNPAID');
  const [statusPrint, setStatusPrint] = useState<number>(0);
  const [salesName, setSalesName] = useState<string>('');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [loadingPayment, setloadingPayment] = useState<boolean>(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState<boolean>(false);
  const [paymentMessage, setPaymentMessage] = useState<string>('');
  const [invoicePayment, setInvoicePayment] = useState<number>(0);
  const [invoicePaymentConfirm, setInvoicePaymentConfirm] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const getQueryParams = () => {
    const params = new URLSearchParams();

    if (invoiceId) {
      params.append('number', invoiceId);
    }

    if (name) {
      params.append('keyword', name);
    }
    if (salesName) {
      params.append('sales', salesName);
    }
    if (statusPayment) {
      params.append('status', statusPayment);
    }

    if (!invoiceId) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    params.append('orderBy', orderBy);
    params.append('ordered', order);
    params.append('page', currentPage.toString());
    params.append('printed', statusPrint.toString());
    return params.toString();
  };

  const fetchData = async () => {
    setIsLoadingData(true);
    setIndexCollapse(-1);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setInvoices(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setStatusPayment('');
    setStatusPrint(0);
    setName('');
    setInvoiceId('');

    setCurrentPage(1);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deleteInvoice = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      await axios.get(`${INVOICE_BASE_URL}/get-total/status`);
      setInvoices(invoices.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handlePaymentConfirm = (id: number) => {
    setInvoicePaymentConfirm(true);
    setInvoicePayment(id);
  };
  const handlePayment = async () => {
    setInvoicePaymentConfirm(false);
    setloadingPayment(true);

    try {
      const body = {
        id: invoicePayment,
        statusPayment: 'PAID',
        paidDate: format(new Date(), 'yyyy-MM-dd'),
        paidBy: currentUser && currentUser.id
      };
      const { data } = await axios.post(`${INVOICE_BASE_URL}/update-invoice-status`, body);
      setloadingPayment(false);
      setOpenPaymentSuccess(true);
      setPaymentMessage(data.message);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const downloadExcel = async () => {
    setLoadingExport(true);

    try {
      const { data } = await axios.get(`${EXPORT_INVOICE_BASE_URL}?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `Invoice-Penjualan-${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingExport(false);
    }
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();

    return () => cancelTokenSource.cancel('No longer latest query');
  }, [orderBy, order, currentPage, startDate, endDate, name, invoiceId, statusPayment, isSuperVisor, isSales, statusPrint, partnerType, salesName]);

  return (
    <Page title='Invoice'>
      <Container>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h1' component='h1'>
                Penjualan
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Breadcrumb />
            </Grid>
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={onRefresh} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid container alignItems='center' item xl={12} lg={8} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography>{`Menampilkan ${total} Invoice (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {startDate && endDate && (
                  <Typography variant='body2' style={{ fontSize: 12, color: PRIMARY }}>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems='center' justify='flex-end' item lg={12} md={12} sm={12} xs={12}>
              <Tooltip title='Calendar filter' placement='top'>
                <Button color='inherit' onClick={event => handleCalendarFilterClick(event)}>
                  <CalendarIcon />
                </Button>
              </Tooltip>
              &nbsp; &nbsp;
              <Tooltip title={'Export'}>
                <Button color='default' onClick={downloadExcel} disabled={isLoadingData || loadingExport}>
                  {loadingExport ? <CircularProgress color='inherit' size={20} /> : <PublishRounded color='primary' />}
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InvoiceTable
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                statusPayment={statusPayment}
                statusPrint={statusPrint}
                invoiceId={invoiceId}
                salesName={salesName}
                setSalesName={setSalesName}
                name={name}
                isLoadingData={isLoadingData}
                count={count}
                loadingPayment={loadingPayment}
                invoicePayment={invoicePayment}
                handlePaymentConfirm={handlePaymentConfirm}
                currentPage={currentPage}
                invoices={invoices}
                order={order}
                orderBy={orderBy}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                setName={setName}
                setInvoiceId={setInvoiceId}
                setStatusPayment={setStatusPayment}
                setStatusPrint={setStatusPrint}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOpenCollapse={handleOpenCollapse}
                loadingItem={loadingItem}
                partnerType={partnerType}
                setPartnerType={setPartnerType}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12} className={classes.fourthGrid}>
              <Grid item>
                {invoices.length > 0 && (
                  <Pagination
                    count={count}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={currentPage}
                    boundaryCount={2}
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Hapus'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteInvoice}
        />
        <ModalSuccess open={openPaymentSuccess} message={paymentMessage} />
        <ModalConfirmation open={invoicePaymentConfirm} handleClose={() => setInvoicePaymentConfirm(false)} handleSubmit={handlePayment} />
      </Container>
    </Page>
  );
};

export default InvoicePage;
