import React, { FC, Fragment } from 'react';
import { TableRow, TableCell, TextField, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Autocomplete, AutocompleteInputChangeReason, Skeleton } from '@material-ui/lab';
import EditItem from 'images/icon/EditItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import { dummyProduct } from 'utils/dummy';
import { CardProduct } from 'components';

interface Props {
  product: ProductModel;
  products: ProductModel[];
  totalItem: number;
  typeUnit: string;
  loadProduct: boolean;
  index: number;
  currentIndex: number;
  isUpdate: boolean;
  productMessage: string;
  totalItemMessage: string;
  hasWarehouse: boolean;
  setProduct: React.Dispatch<React.SetStateAction<ProductModel>>;
  setTotalItem: React.Dispatch<React.SetStateAction<number>>;
  setTypeUnit: React.Dispatch<React.SetStateAction<string>>;
  handleSearchProduct: (value: string) => void;
  handleSaveUpdate?: (index: number) => React.MouseEventHandler;
  handleCloseUpdate?: () => void;
}

const PurchaseOrderItem: FC<Props> = props => {
  const {
    product,
    products,
    totalItem,
    typeUnit,
    isUpdate,
    index,
    currentIndex,
    loadProduct,
    productMessage,
    totalItemMessage,
    hasWarehouse,

    setProduct,
    setTotalItem,
    setTypeUnit,
    handleSearchProduct,
    handleSaveUpdate,
    handleCloseUpdate
  } = props;

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          fullWidth
          id='product'
          value={product}
          disabled={hasWarehouse}
          options={products}
          getOptionLabel={option => option.productName}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event: any, value: any) => {
            if (value) {
              setProduct(value);
              setTypeUnit(value.typeUnit);
            }
          }}
          onOpen={e => handleSearchProduct('')}
          onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
            if (reason === 'clear') {
              setProduct(dummyProduct);
              setTypeUnit('');
              setTotalItem(0);
            }
          }}
          loading={loadProduct}
          renderOption={(option: ProductModel, state) => (loadProduct ? <Skeleton variant='text' width='100%' /> : <CardProduct product={option} />)}
          renderInput={params => (
            <TextField
              {...params}
              required
              fullWidth
              label='Pilih Produk'
              onChange={e => handleSearchProduct(e.target.value)}
              error={productMessage !== ''}
              helperText={productMessage}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loadProduct && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </Fragment>
                )
              }}
            />
          )}
        />
      </TableCell>
      <TableCell align='right' colSpan={!isUpdate ? 2 : undefined}>
        <TextField
          required
          label='Qty'
          value={totalItem}
          onChange={e => setTotalItem(+e.target.value)}
          InputProps={{ endAdornment: ' ' + (typeUnit || '___') }}
          style={{ width: 150 }}
          helperText={totalItemMessage}
          error={totalItemMessage !== ''}
        />
      </TableCell>

      {isUpdate && index === currentIndex && (
        <TableCell align='right'>
          <Tooltip title='Ubah'>
            <IconButton onClick={handleSaveUpdate!(index)} size='small'>
              <img src={EditItem} alt='ubah' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Hapus'>
            <IconButton onClick={handleCloseUpdate!} size='small'>
              <img src={CloseCircleItem} alt='hapus' />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default PurchaseOrderItem;
