import React, { FC, useEffect, useState, useContext, useCallback, Fragment } from 'react';
import { Grid, Container, Typography, Button, Tooltip, makeStyles, Theme, useTheme, useMediaQuery, Tabs, Tab, Divider } from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { Page, StandardConfirmationDialog, Breadcrumb, PaperCustom, a11yProps } from 'components';
import { SALES_ORDER_BASE_URL, SALES_ORDER_EXPORT_BASE_URL } from 'constants/url';
import axios, { CancelTokenSource } from 'axios';
import SalesOrderTable from './components/SalesOrderTable';
import Pagination from '@material-ui/lab/Pagination';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { format } from 'date-fns';
import { dummyMetaData } from 'utils/dummy';
import { GREEN, WHITE } from 'constants/colors';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExportModal from './components/ExportModal';
import useDebounced from 'hooks/useDebounced';
import TypeUser from 'typings/enum/TypeUser';
import ImageModal from 'components/ImageModal';
import FlexBox from 'components/FlexBox';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },

  spacing: {
    margin: '0.5em 0'
  },
  containerBox: {
    marginTop: '0.8em',
    marginBottom: '0.3em',
    gap: '0.6em'
  },

  containerBoxItem: {
    flex: 0.5,
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.07)',
    padding: '14px'
  },
  containerTab: {
    overflow: 'hidden'
  },
  tab: {
    marginLeft: '-1.4em'
  },
  divinder: {
    margin: '1em 0 0'
  }
}));

const SalesOrderPage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const { currentUser } = useContext(CurrentUserContext);
  const isAccess = (currentUser && currentUser.type) || TypeUser.SALES;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [salesOrders, setSalesOrders] = useState<SalesOrderModel[]>([]);
  const [hidePagenation, setHidePagenation] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [metaData, setMetaData] = useState<MetaData>(dummyMetaData);
  const [salesName, setSalesName] = useState<string>('');
  const [salesOrderId, setSalesOrderId] = useState<string>('');
  const [statusOrder, setStatusOrder] = useState<string>('PENDING');
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [dateStart, setDateStart] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const isAddAllowed = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN]
  });

  const isExportAllowed = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.SALES]
  });

  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('partner', name);
      }

      if (salesOrderId) {
        if (salesOrderId.search('#') > -1) {
          params.append('keyword', salesOrderId.substring(1));
        } else {
          params.append('keyword', salesOrderId);
        }
      }

      if (salesName) {
        params.append('sales', salesName);
      }
      params.append('statusOrder', statusOrder);
      params.append('page', currentPage.toString());
      params.append('orderBy', orderBy);

      params.append('ordered', order);
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setSalesOrders(data.data);
      setMetaData(data.meta);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleOpenSO = async (id: number) => history.push(`/penjualan/${id}`);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCreateSalesOrder = () => {
    history.push('/penjualan/tambah');
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    setOrder('asc');
    setOrderBy('id');
    setName('');
    setSalesName('');
    setSalesOrderId('');
    setStatusOrder('PENDING');
    setHidePagenation(false);
    fetchData();
  };

  const handleOpenExport = () => {
    setOpenExport(true);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
    clearExport();
  };

  const handleExport = async () => {
    const params = new URLSearchParams();

    if (date !== '') {
      params.append('date', format(new Date(date), 'yyyy-MM-dd'));
    }

    if (dateStart !== '') {
      params.append('dateStart', format(new Date(dateStart), 'yyyy-MM-dd'));
    }

    if (dateEnd !== '') {
      params.append('dateEnd', format(new Date(dateEnd), 'yyyy-MM-dd'));
    }

    if (status !== '') {
      params.append('status', status);
    }

    try {
      const { data } = await axios.get(`${SALES_ORDER_EXPORT_BASE_URL}?${params.toString()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sales-order.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log('err', err);
    } finally {
      setOpenExport(false);
      clearExport();
    }
  };

  const clearExport = () => {
    setDate('');
    setDateStart('');
    setDateEnd('');
    setStatus('');
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}/${id}`);
      setSalesOrders(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.SalesOrderItem = data.data.SalesOrderItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleOpenConfirmation = () => {
    const so = salesOrders.find(value => value.id === selectedId);
    if (so && so.statusOrder !== 'CONFIRMATION') {
      deleteSalesOrder();
    } else {
      setOpenConfirmation(true);
    }
  };

  async function deleteSalesOrder() {
    if (!openConfirmation) {
      setLoadingDelete(true);
    }

    try {
      await axios.delete(`${SALES_ORDER_BASE_URL}/${selectedId}`);
      setSalesOrders(salesOrders.filter(value => value.id !== selectedId));
      setConfirmationDelete(false);

      handleSnackBar(true, 'success', 'Orderan berhasil dihapus.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Orderan gagal dihapus.');
    } finally {
      setLoadingDelete(false);
    }
  }

  useEffect(() => {
    if (!name && !salesOrderId && !salesName) {
      return;
    }

    setCurrentPage(1);
  }, [name, salesOrderId, salesName]);

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, salesOrderId, salesName, statusOrder, currentUser]);

  return (
    <Page title='Sales Order'>
      <Container>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h1' component='h1'>
                Penjualan
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Breadcrumb />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={1}>
          <PaperCustom>
            <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={7} lg={7} md={12} sm={12} xs={12} container alignItems='center'>
                <FlexBox columnGap={1}>
                  <Tooltip title='Memuat Ulang'>
                    <Button size='small' onClick={handleRefresh} color='inherit' className={classes.refresh}>
                      <RefreshIcon fontSize='small' />
                    </Button>
                  </Tooltip>
                  <Typography variant='h6'>{`Menampilkan ${metaData.total || 0} SO (${metaData.from || 0} - ${metaData.to ||
                    0} dari ${metaData.total || 0})`}</Typography>
                </FlexBox>
              </Grid>

              <Grid item xl={5} lg={5} md={12} sm={12} xs={12} container alignItems='center' justify='flex-end'>
                {isAddAllowed && (
                  <Button
                    onClick={handleCreateSalesOrder}
                    size='small'
                    disabled={isLoadingData}
                    style={{ marginLeft: isAddAllowed ? '2em' : '', marginRight: isAddAllowed ? '1em' : '' }}
                  >
                    <AddBox fontSize='small' /> &nbsp; Order Penjualan
                  </Button>
                )}

                {isExportAllowed && (
                  <Button onClick={handleOpenExport} size='small' color='inherit'>
                    Export
                  </Button>
                )}
              </Grid>
              <Grid xs={12} className={classes.divinder}>
                <Divider />
              </Grid>

              <Grid item sm={12} xs={12}>
                <SalesOrderTable
                  statusOrder={statusOrder}
                  isAccess={isAccess}
                  setStatusOrder={setStatusOrder}
                  salesOrderId={salesOrderId}
                  setSalesOrderId={setSalesOrderId}
                  handleOpenSO={handleOpenSO}
                  name={name}
                  setName={setName}
                  handleOpenImage={handleOpenImage}
                  isLoadingData={isLoadingData}
                  salesOrders={salesOrders}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  salesName={salesName}
                  setSalesName={setSalesName}
                  setOrderBy={setOrderBy}
                  openCollapse={openCollapse}
                  indexCollapse={indexCollapse}
                  handleOpenCollapse={handleOpenCollapse}
                  handleConfirmationDelete={handleConfirmationDelete}
                  loadingItem={loadingItem}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                {salesOrders.length > 0 && !hidePagenation && (
                  <Pagination
                    count={metaData.last_page}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={metaData.current_page}
                    boundaryCount={2}
                    variant='outlined'
                    shape='rounded'
                  />
                )}
              </Grid>
            </Grid>
          </PaperCustom>
        </Grid>

        <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={handleOpenConfirmation}
          isLoading={loadingDelete}
        />

        <ExportModal
          openExport={openExport}
          date={date}
          dateStart={dateStart}
          dateEnd={dateEnd}
          status={status}
          setDate={setDate}
          setDateStart={setDateStart}
          setDateEnd={setDateEnd}
          setStatus={setStatus}
          handleClose={handleCloseExport}
          handleExport={handleExport}
        />
      </Container>
    </Page>
  );
};

export default SalesOrderPage;
