import React, { FC, Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Chip
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb } from 'components';
import {
  PURCHASE_ORDER_BASE_URL,
  PURCHASE_INVOICE_BASE_URL,
  GET_PURCHASE_INVOICE_NUMBER_BASE_URL,
  GET_PURCHASE_ORDER_ITEM_BASE_URL,
  PARTNER_BASE_URL
} from 'constants/url';
import { format, addDays } from 'date-fns';
import { dummyPartner, dummyPurchaseInvoiceItem, dummyProduct } from 'utils/dummy';
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab';
import PurchaseInvoiceCreateTable from './components/PurchaseInvoiceCreateTable';
import NumberFormat from 'react-number-format';
import NumberFormatMask from 'components/NumberFormatMask';
import ReactQuill from 'react-quill';
import useRouter from 'hooks/useRouter';
import Skeleton from '@material-ui/lab/Skeleton';
import { getTotal } from 'utils/getTotal';

type Orders = { id: number; orderNumber: string; warehouse: string; travelDate: Date; logisticPrice: number };

const useStyles = makeStyles({
  cellSummary: {
    border: 'none',
    padding: 5
  },
  cellSummaryRight: {
    border: 'none',
    padding: 5,
    width: '20%'
  },
  editorNotes: {
    marginBottom: '10px'
  },
  wrap: {
    columnGap: '1em'
  }
});

const PurchaseInvoiceCreatePage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [invoiceNumberSupplier, setInvoiceNumberSupplier] = useState<string>('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [orderDateMessage, setOrderDateMessage] = useState<string>('');

  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [loadPartner, setLoadPartner] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const [loadPurchaseOrder, setLoadPurchaseOrder] = useState<boolean>(false);
  const [orders, setOrders] = useState<Orders[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<Orders[]>([]);

  const [purchaseInvoiceItem, setPurchaseInvoiceItem] = useState<PurchaseInvoiceItemModel[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [dueDateMessage, setDueDateMessage] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [purchaseInvoiceItemMessage, setPurchaseInvoiceItemMessage] = useState<string>('');
  const [purchaseOrderMessage, setPurchaseOrderMessage] = useState<string>('');
  const [invoiceNumberMessage, setInvoiceNumberMessage] = useState<string>('');
  const [invoiceNumberSupplierMessage, setInvoiceNumberSupplierMessage] = useState<string>('');
  const [logisticPrice, setlogisticPrice] = useState<number>(0);
  const [logisticMessage, setLogisticMessage] = useState<string>('');

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'SUPPLIER');
    setLoadPartner(true);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  const handleSearchPurchase = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('invoiceReady', 'true');
    params.append('PartnerId', partner.id.toString());

    setLoadPurchaseOrder(true);
    try {
      const { data } = await axios.get(`${PURCHASE_ORDER_BASE_URL}?${params.toString()}`);
      setPurchaseOrders(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPurchaseOrder(false);
    }
  };

  const handlePurchaseOrder = async (order: Orders) => {
    setLoading(true);
    setOrderDate(prevState => (purchaseInvoiceItem.length === 0 ? new Date(order.travelDate) : prevState));

    try {
      const { data } = await axios.get(GET_PURCHASE_ORDER_ITEM_BASE_URL(order.id));
      setOrders(prevState => {
        if (prevState.some(value => value.id === order.id)) {
          setPurchaseInvoiceItem(prevItem =>
            prevItem.filter(value => !data.data.map((val: PurchaseOrderItemModel) => val.ProductId).includes(value.ProductId))
          );
          return prevState.filter(value => value.id !== order.id);
        } else {
          const item = data.data.map((value: any) => ({
            ...dummyPurchaseInvoiceItem,
            ProductId: value.ProductId,
            typeUnit: value.typeUnit,
            totalItem: value.totalItem,
            Product: {
              ...dummyProduct,
              id: value.ProductId,
              productName: value.productName || '',
              productCode: value.productCode || '',
              typeUnit: value.typeUnit
            }
          }));

          setPurchaseInvoiceItem([...purchaseInvoiceItem, ...item]);
          return [...prevState, order];
        }
      });
    } catch (err) {
      console.log('error ', err);
    } finally {
      setLoading(false);
    }
  };

  const handleClearPo = () => {
    setPurchaseInvoiceItem([]);
    setOrders([]);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push('/invoice-pembelian/detail', { id });
  };

  const handleOnSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(PURCHASE_INVOICE_BASE_URL, {
        id,
        invoiceNumber,
        invoiceNumberSupplier,
        orderDate,
        totalPrice,
        totalDiscount,
        PartnerId: partner!.id,
        totalItem: purchaseInvoiceItem.length,
        dueDate,
        items: purchaseInvoiceItem,
        notes,
        logisticPrice: getTotal(orders.map(value => value.logisticPrice)),
        PoId: orders.map(value => ({ id: value.id, logisticPrice: value.logisticPrice }))
      });

      setId(data.data.id);
      handleSnackBar(true, 'success', 'Invoice berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Invoice gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (!partner || partner.id === 0) {
      setPartnerMessage('Nama Mitra tidak boleh kosong.');
      valid = false;
    }

    if (!orderDate) {
      setOrderDateMessage('Tanggal Order tidak boleh kosong.');
      valid = false;
    }

    if (!dueDate) {
      setDueDateMessage('Waktu jatuh tempo tidak boleh kosong');
      valid = false;
    }

    if (purchaseInvoiceItem.length === 0 || !purchaseInvoiceItem) {
      setPurchaseInvoiceItemMessage('Item tidak boleh kosong.');
      valid = false;
    }

    if (orders.length === 0 || !orders) {
      setPurchaseOrderMessage('Nomor Pembelian tidak boleh kosong.');
      valid = false;
    }

    if (invoiceNumber === '' || !invoiceNumber) {
      setInvoiceNumberMessage('Nomor Invoice tidak boleh kosong.');
      valid = false;
    }

    if (invoiceNumberSupplier === '' || !invoiceNumberSupplier) {
      setInvoiceNumberSupplierMessage('Nomor Invoice tidak boleh kosong.');
      valid = false;
    }

    if (purchaseInvoiceItem.some(value => value.price === 0)) {
      setPurchaseInvoiceItemMessage('Harga tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setOrderDateMessage('');
    setDueDateMessage('');
    setPurchaseInvoiceItemMessage('');
    setInvoiceNumberMessage('');
    setInvoiceNumberSupplierMessage('');
    setPurchaseOrderMessage('');
    setLogisticMessage('');
  };

  const handleCancel = () => {
    history.push('/invoice-pembelian');
  };

  const netPrice = (item: PurchaseInvoiceItemModel) => {
    let unitNetPrice = 0;
    let totalNet = 0;

    if (logisticPrice > 0 && item.totalItem > 0 && purchaseInvoiceItem.length > 0 && item.price > 0) {
      unitNetPrice = Math.ceil(logisticPrice / purchaseInvoiceItem.length / item.totalItem) + item.price;
      totalNet = unitNetPrice * item.totalItem;
    }

    return [unitNetPrice, totalNet];
  };

  const handleSetValue = (item: PurchaseInvoiceItemModel) => {
    setPurchaseInvoiceItem(prevState =>
      prevState.map(value => {
        if (value.ProductId === item.ProductId) {
          const [unitNetPrice] = netPrice(item);
          value = {
            ...value,
            price: item.price,
            discount: item.discount,
            totalPrice: item.price * value.totalItem - item.discount,
            unitNetPrice,
            subTotalPrice: item.price * value.totalItem
          };
        }
        return value;
      })
    );
  };

  const handleSetLogisticPrice = (id: number, price: number) => {
    setOrders(prevState =>
      prevState.map(value => {
        if (value.id === id) {
          value = { ...value, logisticPrice: price };
        }
        return value;
      })
    );
  };

  const handleOrderNumber = async () => {
    try {
      const { data } = await axios.get(GET_PURCHASE_INVOICE_NUMBER_BASE_URL);
      setInvoiceNumber(data.data.invoiceNumber);
    } catch (error) {
      console.log('error :', error);
    } finally {
    }
  };

  useEffect(() => {
    if (purchaseInvoiceItem.length === 0) {
      return;
    }
    setTotalPrice(purchaseInvoiceItem.map(value => value.totalPrice).reduce((a, b) => a + b));
    setSubTotalPrice(purchaseInvoiceItem.map(value => value.subTotalPrice).reduce((a, b) => a + b));
    setTotalDiscount(purchaseInvoiceItem.map(value => value.discount).reduce((a, b) => a + b));
  }, [purchaseInvoiceItem]);

  useEffect(() => {
    if (purchaseInvoiceItem.length === 0) return;

    setPurchaseInvoiceItem(prevState =>
      prevState.map(value => {
        const [unitNetPrice] = netPrice(value);
        return { ...value, unitNetPrice };
      })
    );
  }, [logisticPrice]);

  useEffect(() => {
    handleOrderNumber();
  }, []);

  return (
    <Page title='Invoice Pembelian'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Invoice Pembelian </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <Grid container direction='row'>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container direction='row' justify='space-between'>
                <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
                  <Grid item>
                    <Typography>Invoice Pembelian Baru</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={3} justify='space-between'>
              <Grid container lg={6} sm={6} md={6} item spacing={1}>
                <Grid xs={6} item>
                  <TextField
                    id='invoiceNumber'
                    disabled
                    required
                    fullWidth
                    label='No Invoice Pembelian'
                    placeholder='No Invoice Pembelian'
                    value={invoiceNumber}
                    onChange={e => setInvoiceNumber(e.target.value)}
                    error={invoiceNumberMessage !== ''}
                    helperText={invoiceNumberMessage}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    id='dueDate'
                    required
                    fullWidth
                    label='Tanggal Jatuh Tempo'
                    value={format(new Date(dueDate), 'yyyy-MM-dd')}
                    onChange={e => setDueDate(new Date(e.target.value))}
                    error={dueDateMessage !== ''}
                    helperText={dueDateMessage}
                    type='date'
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled
                  />
                </Grid>
                <Grid xs={6} item>
                  <Autocomplete
                    id='partner'
                    value={partner}
                    options={partners}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        setPartner(value);
                        setDueDate(new Date(format(addDays(new Date(orderDate!), value.dueDate || 30), 'yyyy-MM-dd')));
                      }
                    }}
                    onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                      if (reason === 'clear') {
                        setPartner(dummyPartner);
                        setPartners([dummyPartner]);
                        setPurchaseInvoiceItem([]);
                        setOrders([]);
                        setInvoiceNumberSupplier('');
                      }
                    }}
                    onOpen={e => handleSearchPartner('')}
                    loading={loadPartner}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Supplier'
                        onChange={e => handleSearchPartner(e.target.value)}
                        error={partnerMessage !== ''}
                        helperText={partnerMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadPartner && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    id='invoiceNumberSupplier'
                    required
                    fullWidth
                    label='No Invoice dari Supplier'
                    placeholder='No Invoice dari Supplier'
                    value={invoiceNumberSupplier}
                    onChange={e => setInvoiceNumberSupplier(e.target.value)}
                    error={invoiceNumberSupplierMessage !== ''}
                    helperText={invoiceNumberSupplierMessage}
                  />
                </Grid>
              </Grid>
              <Grid xs={6} container item spacing={1} justify='flex-end'>
                <Grid xs={12} item>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id='purchaseOrder'
                    value={orders}
                    options={purchaseOrders}
                    getOptionLabel={option => option.orderNumber}
                    onOpen={e => handleSearchPurchase('')}
                    loading={loadPurchaseOrder}
                    renderOption={option => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            onChange={() => handlePurchaseOrder(option)}
                            checked={orders.some(value => value.id === option.id)}
                            disabled={isLoading}
                          />
                        }
                        label={option.orderNumber}
                      />
                    )}
                    onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                      if (reason === 'clear') {
                        handleClearPo();
                      }
                    }}
                    renderTags={tagValue => tagValue.map((option, index) => <Chip key={option.id} label={option.orderNumber} />)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Nomor PO'
                        onChange={e => handleSearchPurchase(e.target.value)}
                        error={purchaseOrderMessage !== ''}
                        helperText={purchaseOrderMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadPurchaseOrder && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    id='orderDate'
                    required
                    fullWidth
                    type='date'
                    label='Tanggal Surat Jalan'
                    value={orderDate && format(orderDate, 'yyyy-MM-dd')}
                    onChange={e => {
                      setOrderDate(new Date(e.target.value));
                      setDueDate(new Date(format(addDays(new Date(e.target.value), partner.dueDate || 30), 'yyyy-MM-dd')));
                    }}
                    error={orderDateMessage !== ''}
                    helperText={orderDateMessage}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    id='logisticPrice'
                    fullWidth
                    label='Biaya Logistik'
                    value={getTotal(orders.map(value => value.logisticPrice))}
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputComponent: NumberFormatMask as any
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction='row' spacing={1} justify='flex-end' item xl={12} md={12} sm={12}>
              <Grid item xl={6} md={6} sm={12} />

              <Grid item xl={12} md={12} sm={12}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nomor PO</TableCell>
                      <TableCell>Gudang</TableCell>
                      <TableCell>Tanggal Terima Di Gudang</TableCell>
                      <TableCell>Biaya Logistik</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.length === 0 && !isLoading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography align='center'>PO Belum Dipilih</Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    {orders.map(value => (
                      <TableRow key={value.id}>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                color='primary'
                                onChange={() => handlePurchaseOrder(value)}
                                checked={orders.some(val => val.id === value.id)}
                                disabled={isLoading}
                              />
                            }
                            label={value.orderNumber}
                          />
                        </TableCell>
                        <TableCell>{value.warehouse || '-'}</TableCell>
                        <TableCell>{format(new Date(value.travelDate), 'dd/MM/yyyy')}</TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant='standard'
                            size='small'
                            label='Biaya Logistik'
                            value={value.logisticPrice}
                            onChange={e => handleSetLogisticPrice(value.id, +e.target.value)}
                            InputProps={{
                              inputComponent: NumberFormatMask as any
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}

                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Skeleton variant='text' width='100%' />
                        </TableCell>
                      </TableRow>
                    )}

                    {orders.length > 0 && (
                      <TableRow>
                        <TableCell style={{ borderBottom: 'none' }} colSpan={3} align='right'>
                          <b> Total Biaya Logistik </b>
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} align='right'>
                          <b>
                            <NumberFormat
                              value={getTotal(orders.map(value => value.logisticPrice))}
                              prefix={'Rp'}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </b>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            <Grid item xl={12} md={12} sm={12}>
              <FormHelperText error={purchaseInvoiceItemMessage !== ''}>{purchaseInvoiceItemMessage}</FormHelperText>
            </Grid>

            <Grid container direction='row' item xl={12} md={12} sm={12}>
              <Typography variant='body2'>Total Item : {purchaseInvoiceItem.length}</Typography>

              <PurchaseInvoiceCreateTable purchaseInvoiceItem={purchaseInvoiceItem} isLoading={isLoading} handleSetValue={handleSetValue} />
            </Grid>

            <Grid container direction='row' item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid item lg={12} sm={12} md={12}>
                <ReactQuill
                  id='notes-purchase-invoice'
                  value={notes}
                  onChange={(value: any) => setNotes(value)}
                  placeholder='Catatan'
                  className={classes.editorNotes}
                />
              </Grid>
            </Grid>

            <Grid container item xl={6} lg={6} md={6} sm={12} xs={12} justify='flex-end'>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Subtotal</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={subTotalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Harga Logistik</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat
                          value={getTotal(orders.map(value => value.logisticPrice))}
                          prefix={'Rp'}
                          thousandSeparator={true}
                          displayType='text'
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Diskon</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='right' className={classes.cellSummary}>
                        <Typography variant='subtitle2'>Total Harga</Typography>
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryRight}>
                        <NumberFormat value={totalPrice + logisticPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container direction='row' justify='flex-end' spacing={2} item xl={12} lg={12} md={12} sm={12}>
              <Grid item>
                <Button onClick={handleCancel} color='secondary'>
                  Batal
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isSubmit || purchaseInvoiceItem.length === 0 || purchaseInvoiceItem.some(value => value.price === 0)}
                  onClick={handleOnSubmit}
                >
                  {isSubmit ? <CircularProgress color='inherit' size={20} /> : 'Simpan'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />
      </Container>
    </Page>
  );
};

export default PurchaseInvoiceCreatePage;
