import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Search, Today } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import FlexBox from 'components/FlexBox';
import { COURIER_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useRouter from 'hooks/useRouter';
import React, { useCallback, useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import ContentTable from './components/ContentTable';

export type TypeCourierSalaryResource = {
  isLoading: boolean;
  data: CourierSalary[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  }
}));

const CourirSalaryPage = () => {
  const classes = useStyles();
  const { history } = useRouter();
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const [courierSalaryResource, setCourierSalaryResource] = useState<TypeCourierSalaryResource>({
    isLoading: false,
    data: []
  });

  const [keyword, setKeyword] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);

  const handleOpenCreate = () => history.push('/komisi/kalkulasi');

  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const fetchData = useCallback(async () => {
    setCourierSalaryResource(prev => ({ ...prev, isLoading: true }));

    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('page', currentPage.toString());

      params.append('keyword', keyword);

      params.append('dateStart', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('dateEnd', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${COURIER_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setCourierSalaryResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate, currentPage]);

  useEffect(() => {
    fetchData();
    return () => source.cancel('Cancel Unmounted');
  }, [fetchData]);

  const handleSearch = () => fetchData();
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchData();
    }
  };
  return (
    <Page title='Komisi Kurir'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Komisi Kurir
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <Grid xs={12} container>
              <Grid xs={6}>
                <FormControl fullWidth variant='outlined' hiddenLabel size='small'>
                  <OutlinedInput
                    id='keyword'
                    placeholder='Cari nama kurir disini'
                    autoComplete='off'
                    value={keyword}
                    onKeyPress={handleKeyPress}
                    onChange={e => setKeyword(e.target.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton size='small' onClick={handleSearch}>
                          <Search fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <FlexBox columnGap={1} xs={6} justify='flex-end'>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='primary' variant='outlined' onClick={handleCalendarFilterClick}>
                    <Today />
                  </Button>
                </Tooltip>
                <Tooltip title='Tambah data' placement='top'>
                  <Button onClick={handleOpenCreate} size='small' startIcon={<Add fontSize='small' />}>
                    Kalkulasi Komisi
                  </Button>
                </Tooltip>
              </FlexBox>
            </Grid>
            <Grid xs={12}>
              <Typography variant='h6'>
                {`Menampilkan ${meta.total || 0} Komisi Kurir (${meta.from || 0} - ${meta.to || 0} dari ${meta.total || 0})`}
              </Typography>

              <Typography color='primary'>
                Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <ContentTable courierSalaryResource={courierSalaryResource} />
            </Grid>
            {courierSalaryResource.data.length > 0 && (
              <Grid xs={12} container>
                <Pagination
                  count={meta.last_page}
                  onChange={(event, page) => setCurrentPage(page)}
                  page={meta.current_page}
                  boundaryCount={2}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
            <DateRangeFilter
              openCalendarFilter={openCalendarFilter}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleClose={() => setOpenCalendarFilter(false)}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default CourirSalaryPage;
