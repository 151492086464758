import { Grid, makeStyles, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { HeaderRow, StatusPayment, TableCellEnd, TableCellMiddle, TableCellStart, TableCustom, TableRowCustom } from 'components';
import React from 'react';
import { IReport } from 'pages/ReportOrderPage';
import { BLUE, GREY_SECONDARY, PRIMARY_SECONDARY, WHITE } from 'constants/colors';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import clsx from 'clsx';
import PartnerType from 'typings/enum/PartnerType';
import PaymentStatus from 'typings/enum/PaymentStatus';

interface Props<T> {
  data: IReport<T>;
  properties: {
    id: keyof T;
    label: string;
    sort: true;
  }[];
}
const useStyles = makeStyles((theme: Theme) => ({
  link: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: BLUE
    }
  },

  containerEmpty: {
    minHeight: '30vh',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)'
  },
  partnerType: {
    color: WHITE,
    borderRadius: '1.2em',
    padding: '.4em'
  },
  customerColor: {
    background: PRIMARY_SECONDARY
  },
  resellerColor: {
    background: GREY_SECONDARY
  }
}));

const ContentSection = <T,>({ data, properties }: Props<T>) => {
  const classes = useStyles();

  return (
    <Grid xs={12} container>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              headers={properties.map(value => ({
                ...value,
                id: value.id.toString()
              }))}
            />
          </TableHead>

          <TableBody>
            {data.isLoading ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Grid xs={12} container>
                    {[...new Array(5)].map((val, key) => (
                      <Grid xs={12}>
                        <Skeleton variant='text' animation='wave' height={60} key={key} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ) : data.data.length > 0 ? (
              data.data.map((items, key) => (
                <TableRowCustom key={key}>
                  {properties.map((property, index) =>
                    index === 0 ? (
                      <TableCellStart key={String(property.id)}>{items[property.id]}</TableCellStart>
                    ) : index < properties.length - 1 ? (
                      <TableCellMiddle key={String(property.id)}>
                        {property.label.includes('Total') ? (
                          <NumberFormat value={Number(items[property.id])} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                        ) : property.label.includes('Tanggal') ? (
                          (items[property.id] && format(new Date(String(items[property.id])), 'dd MMM yyyy')) || '-'
                        ) : (
                          items[property.id]
                        )}
                      </TableCellMiddle>
                    ) : (
                      <TableCellEnd key={String(property.id)}>
                        <Grid xs={12} container>
                          <StatusPayment
                            status={String(items[property.id]) as PaymentStatus}
                            overdue={
                              Boolean(items['paymentDue' as keyof typeof items])
                                ? new Date().getTime() > new Date(String(items['paymentDue' as keyof typeof items])).getTime()
                                : false
                            }
                            totalPrice={Number(items['totalPrice' as keyof typeof items])}
                            totalPay={Number(items['totalPay' as keyof typeof items])}
                          />
                        </Grid>
                      </TableCellEnd>
                    )
                  )}
                </TableRowCustom>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align='center'>
                  <Grid xs={12} className={classes.containerEmpty} container alignItems='center' justify='center'>
                    <Typography variant='h6'>Data Tidak Tersedia.</Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </Grid>
  );
};

export default ContentSection;
