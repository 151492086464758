import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, makeStyles, MenuItem } from '@material-ui/core';
import BodyRow from './component/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyPurchaseInvoice } from 'utils/dummy';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  purchaseInvoices?: PurchaseInvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  name: string;
  statusPayment: string;
  purchaseInvoiceId: string;
  collapseLoading: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleOpenCollapse: (id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  }
});

const PurchaseInvoiceTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    purchaseInvoices,
    order,
    orderBy,
    indexCollapse,
    openCollapse,
    name,
    purchaseInvoiceId,
    collapseLoading,
    statusPayment,

    setStatusPayment,
    setName,
    setInvoiceId,
    setOrder,
    setOrderBy,
    handleConfirmationDelete,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            key={1}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'invoiceNumber', label: 'Nomor Invoice', sort: true },
              { id: 'name', label: 'Nama Supplier', sort: true },
              { id: 'totalPrice', label: 'Total Harga', sort: true, align: 'right' },
              { id: 'totalItem', label: 'Total Item', sort: true, align: 'center' },
              { id: 'dueDate', label: 'JATUH TEMPO', sort: true },
              { id: 'statusPayment', label: 'Status', sort: true, align: 'center' },
              { label: 'Aksi' }
            ]}
          />
          <TableRow key={2}>
            <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
              <TextField
                placeholder='No Invoice'
                id='purchaseInvoiceId'
                value={purchaseInvoiceId}
                onChange={event => setInvoiceId(event.target.value)}
              />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='name' placeholder='Nama Supplier' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable} align='center'>
              <TextField select id='statusPayment' value={statusPayment} onChange={event => setStatusPayment(event.target.value as string)} fullWidth>
                <MenuItem selected key={1} value='' disabled>
                  Pilih Pembayaran
                </MenuItem>
                <MenuItem key={2} value='PAID'>
                  Lunas
                </MenuItem>
                <MenuItem key={3} value='UNPAID'>
                  Belum Lunas
                </MenuItem>
                <MenuItem key={4} value='CANCEL'>
                  Batal
                </MenuItem>
              </TextField>
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                purchaseInvoice={dummyPurchaseInvoice}
                collapseLoading={collapseLoading}
                onDelete={handleConfirmationDelete(0)}
                handleOpenCollapse={handleOpenCollapse(index)}
              />
            ))
          ) : purchaseInvoices && purchaseInvoices.length > 0 ? (
            purchaseInvoices.map((value, index) => (
              <BodyRow
                key={index}
                index={value.id}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                purchaseInvoice={value}
                collapseLoading={collapseLoading}
                onDelete={handleConfirmationDelete(value.id)}
                handleOpenCollapse={handleOpenCollapse(value.id)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default PurchaseInvoiceTable;
