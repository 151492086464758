import React, { useCallback, useEffect, useState } from 'react';
import { DialogTitle, DialogContent, Dialog } from '@material-ui/core';
import axios from 'axios';
import { isValidEmail } from '../../../../utils';
import { USER_BASE_URL } from '../../../../constants/url';
import CreateUserForm from './components/CreateUserForm';

interface Props {
  open: boolean;
  zone: ZoneModel[];
  roles: RoleModel[];
  addNewUser(user: UserDetailsModel): void;
  warehouses: WareHouseModel[];
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateUserModal: React.FC<Props> = props => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [typeError, setTypeError] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [wareHouse, setWareHouse] = useState<number>(0);
  const [wareHouseError, setWareHouseError] = useState('');
  const { open, addNewUser, handleCancel, setOpenSnackbar, handleSnackBar, zone, roles } = props;

  const [salesRoute, setSalesRoute] = useState<SalesRoute[]>([]);
  const [value, setValue] = useState<number>(0);

  const resetFormValues = useCallback(() => {
    clearFormValue();
    clearFormErrors();
  }, [open]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleClose = () => {
    handleCancel();
    clearFormValue();
    clearFormErrors();
  };

  const clearFormValue = () => {
    setEmail('');
    setFirstName('');
    setContactNumber('');
    setType('');
    setWareHouseError('');
    setWareHouse(0);
  };

  const clearFormErrors = () => {
    setEmailError('');
    setFirstNameError('');
    setContactNumberError('');
    setTypeError('');
    setWareHouseError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!email || !email.trim()) {
      setEmailError('Please enter email');
      ret = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter an valid email');
      ret = false;
    }

    if (!firstName || !firstName.trim()) {
      setFirstNameError('Please enter First Name');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Please enter Contact Number ');
      ret = false;
    }

    if (contactNumber.length < 8) {
      setContactNumberError('Nomor Kontak minimal 8 digit.');
      ret = false;
    }

    if (contactNumber && contactNumber.length < 10) {
      setContactNumberError('Contact Number Min 10 Character');
      ret = false;
    }

    if (!type || !type.trim()) {
      setTypeError('Tipe pengguna tidak boleh kosong');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(USER_BASE_URL, {
        firstName,
        email,
        contactNumber,
        lastName,
        password: '123456',
        route: salesRoute,
        wareHouse: wareHouse,
        typeUser: type
      });

      addNewUser(response.data.data);
      setOpenSnackbar(true);
      handleSnackBar(true, 'success', 'User berhasil ditambahkan');
      handleClose();
    } catch (err) {
      setOpenSnackbar(true);
      handleSnackBar(true, 'error', 'User gagal dihapus.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeWareHouse = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setWareHouse(0);
    } else {
      setWareHouse(+event.target.value);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'body'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{'Tambah Pengguna'}</DialogTitle>
      <DialogContent>
        <CreateUserForm
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          firstName={firstName}
          wareHouse={wareHouse}
          setFirstName={setFirstName}
          firstNameError={firstNameError}
          lastName={lastName}
          setLastName={setLastName}
          contactNumber={contactNumber}
          setContactNumber={setContactNumber}
          contactNumberError={contactNumberError}
          handleChangeWareHouse={handleChangeWareHouse}
          wareHouseError={wareHouseError}
          setValue={setValue}
          warehouses={props.warehouses}
          type={type}
          isLoading={isLoading}
          handleOnSubmit={handleOnSubmit}
          handleOnCancel={handleClose}
          setType={setType}
          typeError={typeError}
          roles={roles}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
