import { Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom, TabPanel } from 'components';
import { PARTNER_BASE_URL, REPORT_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import getCurrency from 'utils/getCurrency';
import ContentSection from './components/ContentSection';
import FilterSection from './components/FilterSection';
import HeaderSection from './components/HeaderSection';
import TabSection from './components/TabSection';
import TotalSection from './components/TotalSection';

interface ITotal {
  total: {
    totalPrice: number;
    totalItem: number;
    totalBilled: number;
    totalRemainingBill: number;
  };
}
export interface IReport<T> {
  isLoading: boolean;
  data: T[];
}

export interface IFilter {
  partnerId: number;
  partnerName: string;
  from: Date;
  to: Date;
  statusPayment: string;
}

interface mapOptions<T> {
  [key: number]: T;
}

const dummyData = {
  isLoading: false,
  data: [],
  total: { totalPrice: 0, totalItem: 0, totalBilled: 0, totalRemainingBill: 0 }
};
const dummyFilter = {
  partnerId: 0,
  partnerName: '',
  from: startOfMonth(new Date()),
  to: new Date(),
  statusPayment: ''
};

const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  }
});

const ReportOrderPage = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<MetaData>(dummyMetaData);
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [partners, setPartners] = useState<IReport<PartnerModel>>({
    isLoading: false,
    data: []
  });
  const [filter, setFilter] = useState<IFilter>(dummyFilter);
  const [orderInvoiceResource, setOrderInvoiceResource] = useState<IReport<InvoiceModel> & ITotal>(dummyData);
  const [purchaseInvoiceResource, setPurchaseInvoiceResource] = useState<
    IReport<PurchaseInvoiceModel & { partnerName: string; partnerType: string }> & ITotal
  >(dummyData);

  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  const fetchData = async () => {
    const params = new URLSearchParams();
    if (filter.partnerId !== 0) {
      params.append('partnerId', String(filter.partnerId));
    }
    if (filter.statusPayment !== '') {
      params.append('status', filter.statusPayment);
    }
    params.append('startDate', format(filter.from, 'yyyy-MM-dd'));
    params.append('endDate', format(filter.to, 'yyyy-MM-dd'));

    const getTab = () => {
      if (tab === 0) {
        setOrderInvoiceResource(prev => ({ ...prev, isLoading: true }));
        return 'invoice';
      } else {
        setPurchaseInvoiceResource(prev => ({ ...prev, isLoading: true }));
        return 'purchase-invoice';
      }
    };
    try {
      const { data } = await axios(`${REPORT_BASE_URL}/${getTab()}?${params}`, {
        cancelToken
      });

      if (tab === 0) {
        setOrderInvoiceResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      } else {
        setPurchaseInvoiceResource(prev => ({ ...prev, isLoading: false, data: data.data }));
      }
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPartner = async () => {
    setPartners(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();
    if (filter.partnerName) {
      params.append('keyword', filter.partnerName);
    }

    const partnerType: mapOptions<string> = {
      0: 'CUSTOMER,RESELLER',
      1: 'SUPPLIER'
    };

    params.append('partnerType', partnerType[tab]);

    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`, {
        cancelToken
      });

      setPartners(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = async () => {
    setIsLoadingDownload(true);
    const params = new URLSearchParams();
    if (filter.partnerId !== 0) {
      params.append('partnerId', String(filter.partnerId));
    }
    if (filter.statusPayment !== '') {
      params.append('status', filter.statusPayment);
    }
    params.append('startDate', format(filter.from, 'yyyy-MM-dd'));
    params.append('endDate', format(filter.to, 'yyyy-MM-dd'));
    const getTab = () => {
      if (tab === 0) {
        return 'pdf-invoice';
      } else {
        return 'pdf-purchase-invoice';
      }
    };
    try {
      const { data } = await axios(`${REPORT_BASE_URL}/${getTab()}?${params}`, { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      setIsLoadingDownload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataTotal = async () => {
    const params = new URLSearchParams();
    if (filter.partnerId !== 0) {
      params.append('partnerId', String(filter.partnerId));
    }
    if (filter.statusPayment !== '') {
      params.append('status', filter.statusPayment);
    }
    params.append('startDate', format(filter.from, 'yyyy-MM-dd'));
    params.append('endDate', format(filter.to, 'yyyy-MM-dd'));

    const getTab = () => {
      if (tab === 0) {
        setOrderInvoiceResource(prev => ({ ...prev, isLoading: true }));
        return 'get-total-invoice';
      } else {
        setPurchaseInvoiceResource(prev => ({ ...prev, isLoading: true }));
        return 'get-total-purchase-invoice';
      }
    };
    try {
      const { data } = await axios(`${REPORT_BASE_URL}/${getTab()}?${params}`, {
        cancelToken
      });

      if (tab === 0) {
        setOrderInvoiceResource(prev => ({ ...prev, isLoading: false, total: { ...prev.total, ...data.data } }));
      } else {
        setPurchaseInvoiceResource(prev => ({ ...prev, isLoading: false, total: { ...prev.total, ...data.data } }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const parnetName = useDebounce(filter.partnerName, 500);

  useEffect(() => {
    if (tab > 1) return;
    fetchPartner();
    return () => source.cancel('Cancel Unmounted');
  }, [tab, parnetName]);

  useEffect(() => {
    fetchDataTotal();
    return () => source.cancel('Cancel Unmounted');
  }, [tab, filter, currentPage]);

  useEffect(() => {
    fetchData();
    return () => source.cancel('Cancel Unmounted');
  }, [tab, filter, currentPage]);

  const handleRefresh = useCallback(() => {
    setFilter(dummyFilter);
  }, []);

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    handleRefresh();
  };

  const handleChangeFilter = <T,>(key: string, value: T) => setFilter(prev => ({ ...prev, [key]: value }));

  const memoTotalResource = useMemo(() => {
    const totalPriceMap: mapOptions<number> = {
      0: orderInvoiceResource.total.totalPrice,
      1: purchaseInvoiceResource.total.totalPrice
    };

    const totalBilledMap: mapOptions<number> = {
      0: orderInvoiceResource.total.totalBilled,
      1: purchaseInvoiceResource.total.totalBilled
    };

    const totalRemainingBillMap: mapOptions<number> = {
      0: orderInvoiceResource.total.totalRemainingBill,
      1: purchaseInvoiceResource.total.totalRemainingBill
    };
    return (
      <TotalSection
        totalResource={[
          {
            label: 'Total keseluruhan invoice',
            content: getCurrency(totalPriceMap[tab])
          },
          { label: 'Total Sisa Tagihan', content: getCurrency(totalBilledMap[tab]) },
          { label: 'Total Tertagih', content: getCurrency(totalRemainingBillMap[tab]) }
        ]}
      />
    );
  }, [orderInvoiceResource, purchaseInvoiceResource]);

  const getLabel = {};
  return (
    <Page title='Laporan Order'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Laporan
          </Typography>
          <Breadcrumb />
        </Grid>
        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <TabSection
              tabs={{ tab, handleTab }}
              labels={['laporan Invoice penjualan', 'laporan invoice pembelian']}
              onClick={{ isLoadingDownload, handleDownload }}
            />
            <FilterSection partners={partners} handleChangeFilter={handleChangeFilter} filter={filter} tab={tab} />
            <HeaderSection
              dateLabel={[
                tab === 0
                  ? `Menampilkan ${meta.total} invoice penjualan (${meta.from || 0} - ${meta.to || 0} dari ${meta.total})`
                  : `Menampilkan ${meta.total} invoice pembelian (${meta.from || 0} - ${meta.to || 0} dari ${meta.total})`,
                `Data tanggal ${format(filter.from, 'dd-MM-yyyy')} s/d ${format(filter.to, 'dd-MM-yyyy')}`
              ]}
              onClick={{ isLoadingDownload: false, handleRefresh }}
            />
            <Grid xs={12}>
              <Divider />
            </Grid>
            {memoTotalResource}
            <Grid xs={12}>
              <TabPanel value={tab} index={0}>
                <ContentSection
                  data={orderInvoiceResource}
                  properties={[
                    { id: 'invoiceNumber', label: 'No Invoice', sort: true },
                    { id: 'partnerName', label: 'Nama Mitra', sort: true },
                    { id: 'createdAt', label: 'Tanggal Terbit', sort: true },
                    { id: 'totalPrice', label: 'Total Tagihan', sort: true },
                    { id: 'totalPay', label: 'Total Tertagih', sort: true },
                    { id: 'paidDate', label: 'Tanggal Pembayaran', sort: true },
                    { id: 'statusPayment', label: 'Status Pembayaran', sort: true }
                  ]}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ContentSection
                  data={purchaseInvoiceResource}
                  properties={[
                    { id: 'invoiceNumber', label: 'No Invoice', sort: true },
                    { id: 'partnerName', label: 'Nama Mitra', sort: true },
                    { id: 'orderDate', label: 'Tanggal Terbit', sort: true },
                    { id: 'totalPrice', label: 'Total Tagihan', sort: true },
                    { id: 'totalPay', label: 'Total Tertagih', sort: true },
                    { id: 'paidDate', label: 'Tanggal Pembayaran', sort: true },
                    { id: 'statusPayment', label: 'Status Pembayaran', sort: true }
                  ]}
                />
              </TabPanel>
            </Grid>
          </Grid>
          <Grid xs={12} container justify='flex-end'>
            <Pagination
              count={meta.last_page}
              onChange={(event, page) => setCurrentPage(page)}
              page={meta.current_page}
              boundaryCount={2}
              variant='outlined'
              shape='rounded'
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ReportOrderPage;
