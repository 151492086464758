import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import ModalDelete from './ModalDelete';
import { Delete } from '@material-ui/icons';
import { useProductCart } from 'contexts/ProductCartContext';
import NumberFormat from 'react-number-format';
import PartnerType from 'typings/enum/PartnerType';
import { NumberFormatMask } from 'components';
interface Props {}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  wrapper: {
    minHeight: '50vh'
  },
  body: {
    border: '1px solid #E0E0E0',
    borderRadius: '.5em',
    padding: '.4em 1em'
  },
  header: {
    padding: '.4em 1em',
    '& > *': {
      textTransform: 'uppercase'
    }
  },
  empty: {
    height: '50vh',
    border: '1px solid #E0E0E0',
    borderRadius: '.5em'
  }
}));
const ProductCart = ({}: Props) => {
  const styles = useStyles();
  const [isDelete, setIsDelete] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const { productCart, removeFromCart, changeQuantityDetail, changePrice } = useProductCart();

  const handleClose = () => setIsDelete({ ...isDelete, ['isOpen']: false });
  const getProductId = (id: number) => setIsDelete({ ...isDelete, ['id']: id, ['isOpen']: true });
  const handleSubmit = () => {
    removeFromCart(isDelete.id);
    handleClose();
  };
  return (
    <Grid xs={12} container className={styles.container}>
      <Grid xs={12} container className={styles.header}>
        <Grid xs={3}>
          <Typography color='textSecondary' variant='h6'>
            Nama Produk
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography color='textSecondary' variant='h6'>
            Satuan Harga
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography color='textSecondary' variant='h6'>
            Harga Jual
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography color='textSecondary' variant='h6'>
            kuantitas
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography color='textSecondary' variant='h6'>
            jumlah harga
          </Typography>
        </Grid>
        <Grid xs={1} />
      </Grid>
      <Grid xs={12} container className={styles.container}>
        {productCart.length > 0 ? (
          productCart.map((product, index) => (
            <Grid xs={12} container className={styles.body} key={index} alignItems='center'>
              <Grid xs={3}>
                <Grid xs={11}>
                  <Typography>{product.productName}</Typography>
                </Grid>
              </Grid>
              <Grid xs={2}>
                <Typography>{(product.Category && product.Category.name) || '-'}</Typography>
              </Grid>
              <Grid xs={2}>
                <Grid xs={11}>
                  <TextField
                    id='total'
                    required
                    fullWidth
                    value={product.price}
                    size='small'
                    onChange={event => changePrice(product.ProductId, +event.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatMask as any
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={2} container>
                <Grid xs={9}>
                  <FormControl variant='outlined' size='small' required fullWidth>
                    <OutlinedInput
                      id='quantity'
                      inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                      endAdornment={<InputAdornment position='end'>{product.typeUnit}</InputAdornment>}
                      onChange={event => changeQuantityDetail(product.ProductId, +event.target.value)}
                      value={product.quantity}
                      autoComplete='off'
                      error={product.quantity === 0 || product.quantity > product.salesStock}
                    />
                  </FormControl>
                  {product.quantity > product.salesStock && <FormHelperText error={true}>Melebihi stok ({product.salesStock})</FormHelperText>}
                </Grid>
              </Grid>
              <Grid xs={2}>
                <Typography align='center'>
                  <NumberFormat value={product.totalPrice.toFixed(2)} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </Typography>
              </Grid>

              <Grid xs={1} container justify='flex-end'>
                <IconButton aria-label='cart' size='small' onClick={() => getProductId(product.ProductId)}>
                  <Delete color='error' fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid xs={12} container justify='center' alignItems='center' className={styles.empty}>
            <Typography variant='h6' align='center'>
              Tidak ada produk di keranjang
            </Typography>
          </Grid>
        )}
      </Grid>
      <ModalDelete open={isDelete.isOpen} handleClose={handleClose} handleSubmit={handleSubmit} />
    </Grid>
  );
};

export default ProductCart;
