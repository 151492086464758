import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummySalesOrder } from 'utils/dummy';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  loadingItem: boolean;
  salesOrders: SalesOrderModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  handleOpenImage: (imagePath: string) => void;
  statusOrder: string;
  handleOpenSO: (id: number) => void;
  setStatusOrder: React.Dispatch<React.SetStateAction<string>>;
  salesOrderId: string;
  salesName: string;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
  setSalesOrderId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  indexCollapse: number;
  openCollapse: boolean;
  isAccess: string;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650
  }
}));

const SalesOrderTable: FC<Props> = props => {
  const {
    isLoadingData,
    salesOrders,
    order,
    orderBy,
    name,
    setName,
    isAccess,
    statusOrder,
    setStatusOrder,
    salesOrderId,
    salesName,
    setSalesName,
    setSalesOrderId,
    setOrder,
    setOrderBy,
    handleOpenSO,
    handleOpenImage,
    handleConfirmationDelete,
    indexCollapse,
    openCollapse,
    loadingItem,
    handleOpenCollapse
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'salesOrderId', label: 'Nomor Order', sort: true },
              { id: 'orderDate', label: 'TGL ORDER', sort: true },
              { id: 'name', label: 'NAMA CUSTOMER', sort: true },
              { id: 'nameSales', label: 'NAMA SALES', sort: true },
              { id: 'totalAmount', label: 'TOTAL HARGA', sort: true },
              { id: 'statusOrder', label: 'STATUS', sort: true }
            ]}
          />

          <TableRow>
            <TableCellHead variant='head' colSpan={2}>
              <TextField
                id='salesOrderId'
                placeholder='No Order'
                autoComplete='off'
                value={salesOrderId}
                onChange={event => setSalesOrderId(event.target.value)}
              />
            </TableCellHead>
            <TableCellHead />
            <TableCellHead variant='head'>
              <TextField id='name' placeholder='Nama' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head'>
              <TextField
                id='salesName'
                placeholder='Sales'
                value={salesName}
                onChange={event => setSalesName(event.target.value)}
                autoComplete='off'
              />
            </TableCellHead>
            <TableCellHead />
            <TableCellHead variant='head'>
              <TextField
                select
                id='statusOrder'
                value={statusOrder}
                autoComplete='off'
                onChange={event => setStatusOrder(event.target.value as string)}
              >
                <MenuItem key={1} value='' selected>
                  Pilih Status
                </MenuItem>
                <MenuItem key={2} value='PENDING'>
                  Tertunda
                </MenuItem>
                <MenuItem key={3} value='CONFIRMATION'>
                  Konfirmasi
                </MenuItem>
                <MenuItem key={4} value='CANCEL'>
                  Batal
                </MenuItem>
              </TextField>
            </TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData
            ? [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  isAccess={isAccess}
                  isLoading={isLoadingData}
                  salesOrder={dummySalesOrder}
                  onDelete={handleConfirmationDelete(0)}
                  handleOpenSO={() => console.log('')}
                  index={index}
                  indexCollapse={indexCollapse}
                  handleOpenImage={handleOpenImage}
                  openCollapse={openCollapse}
                  handleOpenCollapse={handleOpenCollapse(index, 0)}
                  loadingItem={loadingItem}
                />
              ))
            : salesOrders && salesOrders.length > 0
            ? salesOrders.map((value, index) => (
                <BodyRow
                  key={index}
                  isAccess={isAccess}
                  isLoading={isLoadingData}
                  salesOrder={value}
                  onDelete={handleConfirmationDelete(value.id)}
                  index={index}
                  handleOpenSO={handleOpenSO}
                  handleOpenImage={handleOpenImage}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  handleOpenCollapse={handleOpenCollapse(index, value.id)}
                  loadingItem={loadingItem}
                />
              ))
            : isLoadingData && (
                <TableRow>
                  <TableCell colSpan={9} align='center'>
                    Data Tidak Tersedia.
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default SalesOrderTable;
