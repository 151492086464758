import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
interface Props {
  delivery: DeliveryOrders;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    padding: '1em',
    cursor: 'pointer',
    transition: 'all 1s ease',
    '&:hover': {
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
    }
  }
}));
const ContentData = ({ delivery }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={4}>
        <Typography>{delivery.deliveryNumber}</Typography>
      </Grid>
      <Grid xs={3}>
        <Typography>{delivery.partnerName}</Typography>
      </Grid>
      <Grid xs={3}>
        <Typography>{delivery.createdAt}</Typography>
      </Grid>
      <Grid xs={2}>
        <Typography align='center'>{delivery.totalKoli}</Typography>
      </Grid>
    </Grid>
  );
};

export default ContentData;
