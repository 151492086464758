import React, { FC, memo, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import NumberFormatMask from 'components/NumberFormatMask';
import { YELLOW } from 'constants/colors';
import { format } from 'date-fns';
import PaymentStatus from 'typings/enum/PaymentStatus';

interface Props {
  open: boolean;
  purchasePayment: InvoicePaymentModel;
  isLoading: boolean;
  totalPrice: number;
  handleChangePurchasePayment: <T>(key: string, value: T) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  handlePaymentStatus: (type: 'PAID' | 'UNPAID') => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: '3em',
    color: YELLOW
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const PurchasePaymentModal = ({
  open,
  handleClose,
  handleSubmit,
  handlePaymentStatus,
  purchasePayment,
  isLoading,
  totalPrice,
  handleChangePurchasePayment
}: Props) => {
  const classes = useStyles();
  const [isFull, setIsFull] = useState<boolean>(true);
  const validation = !purchasePayment.paymentMethod || (!isFull && purchasePayment.totalPay < 1) || purchasePayment.totalPay > totalPrice;

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <Typography variant='h5'>Pembayaran!</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container>
          <TextField
            id='paymentMethod'
            required
            label='Metode Pembayaran'
            onChange={e => handleChangePurchasePayment<string>('paymentMethod', e.target.value)}
            select
            fullWidth
          >
            <MenuItem key={1} disabled>
              Pilih Metode Bayar
            </MenuItem>
            <MenuItem key={2} value='CASH'>
              CASH
            </MenuItem>
            <MenuItem key={3} value='Bank Transfer'>
              Bank Transfer
            </MenuItem>
          </TextField>
          <TextField
            id='payDate'
            required
            fullWidth
            type='date'
            label='Tanggal Surat Jalan'
            value={purchasePayment.payDate && format(purchasePayment.payDate, 'yyyy-MM-dd')}
            onChange={e => handleChangePurchasePayment<Date>('payDate', new Date(e.target.value))}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            fullWidth
            id='note'
            label='Catatan'
            multiline
            rows={4}
            value={purchasePayment.note}
            onChange={e => handleChangePurchasePayment<string>('note', e.target.value)}
          />
          <RadioGroup row aria-label='position' name='position' defaultValue='end'>
            <FormControlLabel
              onChange={() => {
                setIsFull(true);
                handlePaymentStatus(PaymentStatus.PAID);
              }}
              value='1'
              control={<Radio color='primary' checked={isFull} />}
              label='Bayar Full'
              labelPlacement='end'
            />
            <FormControlLabel
              onChange={() => {
                setIsFull(false);
                handlePaymentStatus(PaymentStatus.UNPAID);
              }}
              value='0'
              control={<Radio color='primary' checked={!isFull} />}
              label='Bayar Sebagian'
              labelPlacement='end'
            />
          </RadioGroup>
          <TextField
            label='Jumlah Bayar'
            name='totalPay'
            fullWidth
            disabled={isFull}
            error={purchasePayment.totalPay > totalPrice}
            helperText={
              purchasePayment.totalPay > totalPrice && `Nominal melebihi sisa tagihan (Rp${new Intl.NumberFormat('id-ID').format(totalPrice)})`
            }
            value={purchasePayment.totalPay}
            onChange={e => handleChangePurchasePayment<number>('totalPay', +e.target.value)}
            InputProps={{
              inputComponent: NumberFormatMask as any
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='space-evenly'>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} variant='outlined' color='primary' onClick={handleClose}>
              Kembali
            </Button>
          </Grid>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleSubmit} disabled={validation || isLoading}>
              {isLoading ? <CircularProgress size={20} color='primary' /> : 'Bayar'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PurchasePaymentModal;
