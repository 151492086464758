import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { YELLOW } from 'constants/colors';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: '3em',
    color: YELLOW
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const ModalConfirmation: FC<Props> = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded className={classes.icon} />
          <Typography variant='h5'>Konfirmasi!</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container justify='center'>
          <Typography align='center'>Apa kah anda yakin melakukan pembayaran?</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='space-evenly'>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} variant='outlined' color='primary' onClick={handleClose}>
              Kembali
            </Button>
          </Grid>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleSubmit}>
              Bayar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmation;
