import React, { FC, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  TableContainer,
  Table,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Button,
  Fade
} from '@material-ui/core';
import clx from 'clsx';
import ListProduct from '../ListProduct';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { BLACK, WHITE, GREEN, BLUE } from 'constants/colors';
import Logo from 'images/Logo.png';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';
import ModalPermission from './ModalPermission';

interface Props {
  invoice: InvoiceModel;
  company: CompanyModel;
  isLoadingData: boolean;
  invoiceItem: InvoiceItemModel[];
  invoicePayment?: InvoicePaymentModel[];
  openTotalPrice: boolean;
  handleClickInvoicePayment: (id: number) => void;
  loadingTotalPrice: boolean;
  isFinance: boolean;
  isInvoice: boolean;
  totalPrice: number;
  isRequestEdit: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  item: { id: number; notes: string; isRequestEdit: boolean }[];
  indexCollapse: number;
  indexEdit: number;
  type: string;
  loadingEdit: boolean;
  loadingRequest: boolean;
  isCancel: boolean;
  handleOpenRemark: () => void;
  onDeleteItem: (id: number) => void;
  onRefresh: () => void;
  onUpdate: () => void;
  individualEdit: (item: InvoiceItemModel) => void;
  handleRequest: () => void;
  handleEdit: (index: number, type: string) => void;
  handleOpenCollapse: (index: number) => React.MouseEventHandler;
  handleRequestNote: (item: { id: number; notes: string }) => void;
  handleRequestCheck: (item: { id: number; notes: string; isRequestEdit: boolean }) => void;
  handleRequestEdit: () => void;
  handleOpenUpdateTotalPrice: () => void;
  handleCancelUpdateTotalPrice: () => void;
  updateTotalPrice: () => void;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  setOpenPartner: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '35%',
    border: 'none',
    padding: '4px 4px 4px 4px',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellSummaryRightLink: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    cursor: 'pointer',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLUE,
    textDecorationLine: 'underline'
  },
  note: {
    overflowX: 'auto'
  },
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  textTooltip: {
    color: WHITE
  },
  timeOut: {
    animation: 'shake 0.5s',
    animationIterationCount: 'infinite'
  },
  logo: {
    objectFit: 'cover',
    height: '10em'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: ' translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: ' translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: ' translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: ' translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: ' translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: ' translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: ' translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: ' translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: ' translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: ' translate(1px, -2px) rotate(-1deg)' }
  }
}));

const ContentInvoice: FC<Props> = props => {
  const { history } = useRouter();
  const classes = useStyles();
  const {
    invoice,
    isLoadingData,
    company,
    invoiceItem,
    invoicePayment,
    openTotalPrice,
    totalPrice,
    isFinance,
    loadingTotalPrice,
    isRequestEdit,
    item,
    indexCollapse,
    indexEdit,
    type,
    isCancel,
    loadingEdit,
    handleClickInvoicePayment,
    loadingRequest,
    isAdmin,
    isSuperAdmin,
    onDeleteItem,
    onRefresh,
    handleEdit,
    isInvoice,
    onUpdate,
    handleRequest,
    individualEdit,
    handleOpenCollapse,
    handleRequestNote,
    handleRequestCheck,
    handleRequestEdit,
    setTotalPrice,
    handleOpenUpdateTotalPrice,
    handleCancelUpdateTotalPrice,
    updateTotalPrice,
    setOpenPartner
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const realOriginalPrice = totalPrice + invoice.totalDiscount + invoice.totalPiece;

  const handleLink = (id: Number) => {
    if (isFinance || isInvoice) {
      setOpen(true);
    } else {
      history.push(`/penjualan/${id}`);
    }
  };
  const handleLinkSJ = (id: Number) => {
    history.push(`/surat-jalan/${id}`);
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Hidden smDown xsDown>
        <Grid item lg={8} sm={8} md={8} xs={8}>
          <img src={Logo} alt='company' className={classes.logo} />
        </Grid>
      </Hidden>

      <Grid container item lg={4} sm={12} md={4} xs={12} justify='flex-end'>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                  <Typography variant='h1' className={classes.titleOrder}>
                    Invoice Penjualan
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>ID Penjualan</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.invoiceNumber}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Ref So</Typography>
                </TableCell>
                <TableCell
                  align='right'
                  className={clx(classes.cellOrder, classes.cellOrderRightUnderline)}
                  onClick={() => handleLink(invoice.SalesOrderId || 0)}
                >
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.orderNumber || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Ref SJ</Typography>
                </TableCell>
                <TableCell
                  align='right'
                  className={clx(classes.cellOrder, classes.cellOrderRightUnderline)}
                  onClick={() => handleLinkSJ(invoice.deliveryId || 0)}
                >
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.deliveryNumber || '-'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Tanggal Order</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : invoice.orderDate ? (
                    format(new Date(invoice.orderDate), 'dd-MM-yyyy')
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Jatuh Tempo</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : format(new Date(invoice.dueDate), 'dd-MM-yyyy')}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                  <Typography variant='subtitle2'>Sales</Typography>
                </TableCell>
                <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.SalesName || '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={12} md={6} xs={12}>
        <Typography variant='h5' align='left'>
          Nama Perusahaan
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Telp: ' + company.phoneNumber || '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' className={classes.cellInfoLeft}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography variant='h5' align='right'>
          Order Penjualan dari
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell onClick={() => setOpenPartner(true)} align='right' className={classes.cellOrderRightUnderline}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : invoice.Partner ? invoice.Partner.name : '-'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : invoice.Partner ? (
                    <Tooltip
                      interactive
                      arrow
                      open={!invoice.Partner.remarkAddress || invoice.Partner.remarkAddress === ''}
                      title={
                        <>
                          <div style={{ textAlign: 'center' }}>
                            <Typography gutterBottom>Remark Alamat belum diisi</Typography>
                            <Button size='small' onClick={props.handleOpenRemark}>
                              Klik disini
                            </Button>
                          </div>
                        </>
                      }
                    >
                      <span
                        style={{ textDecoration: !invoice.Partner.remarkAddress || invoice.Partner.remarkAddress === '' ? 'underline' : 'none' }}
                      >{`${invoice.Partner.address}, (${invoice.Partner.remarkAddress || '-'})`}</span>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : `Telp ${(invoice.Partner && invoice.Partner.phoneNumber) || '-'}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? (
                    <Skeleton variant='text' width='100%' />
                  ) : (
                    'No Hp: ' + ((invoice.Partner && invoice.Partner.cellPhoneNumber) || '-')
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellInfoRight}>
                  {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + ((invoice.Partner && invoice.Partner.email) || '-')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography variant='body2'>Total Item : {invoiceItem.length}</Typography>
      </Grid>

      <Grid container justify='flex-end' item lg={6} sm={6} md={6} xs={6}>
        {isAdmin && (
          <FormControlLabel
            value='start'
            control={<Checkbox checked={isRequestEdit} onChange={handleRequestEdit} color='primary' />}
            label='Kirim Permintaan Edit'
            labelPlacement='start'
            disabled={isCancel}
          />
        )}
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <ListProduct
          isCanceledInvoice={isCancel}
          isRequestEdit={isRequestEdit}
          isLoadingData={isLoadingData}
          invoiceItem={invoiceItem}
          item={item}
          indexCollapse={indexCollapse}
          indexEdit={indexEdit}
          type={type}
          loadingEdit={loadingEdit}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          onDeleteItem={onDeleteItem}
          onUpdate={onUpdate}
          individualEdit={individualEdit}
          handleEdit={handleEdit}
          handleOpenCollapse={handleOpenCollapse}
          handleRequestNote={handleRequestNote}
          handleRequestCheck={handleRequestCheck}
        />
      </Grid>

      <Grid item lg={6} sm={6} md={6} xs={6}>
        <Fade in={isRequestEdit}>
          <Button size='small' onClick={handleRequest}>
            Simpan Perubahan dari ({item.length} Item) {loadingRequest && <CircularProgress color='inherit' size='small' />}
          </Button>
        </Fade>
      </Grid>

      <Grid container justify='flex-end' item lg={6} sm={6} md={6} xs={6}>
        {!isSuperAdmin && (
          <Tooltip title='Memuat Ulang'>
            <Button size='small' color='inherit' onClick={onRefresh} className={classes.refresh}>
              Memuat Ulang
            </Button>
          </Tooltip>
        )}
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Subtotal
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={invoice.subTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Potongan Lainya
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={invoice.deduction || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              {invoicePayment &&
                invoicePayment.length > 0 &&
                invoicePayment.map((payment, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align='right' className={classes.cellSummaryRightLink} onClick={() => handleClickInvoicePayment(payment.id)}>
                        {payment.paymentNumber}
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryLeft}>
                        <NumberFormat value={payment.totalPay} prefix={'(Rp'} suffix={')'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Total Harga
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ModalPermission setOpen={setOpen} open={open} />
    </Grid>
  );
};

export default ContentInvoice;
