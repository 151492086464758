import React, { FC, useContext } from 'react';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  makeStyles,
  MenuItem,
  Select,
  ListItemText,
  Checkbox
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyInvoice } from 'utils/dummy';
import BodyRow from './components/BodyRow';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  invoicePayment: number;
  invoices?: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  name: string;
  statusPayment: string;
  statusPrint: number;
  invoiceId: string;
  loadingItem: boolean;
  loadingPayment: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setStatusPrint: React.Dispatch<React.SetStateAction<number>>;
  handlePaymentConfirm: (id: number) => void;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
  partnerType: string;
  setPartnerType: React.Dispatch<React.SetStateAction<string>>;
  salesName: string;
  setSalesName: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  },
  selectStatus: {
    width: '150px'
  }
});

const InvoiceTable: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);

  const classes = useStyles();
  const {
    isLoadingData,
    loadingItem,
    invoices,
    order,
    orderBy,
    indexCollapse,
    openCollapse,
    name,
    invoiceId,
    invoicePayment,
    statusPayment,
    statusPrint,
    isSuperAdmin,
    handlePaymentConfirm,
    loadingPayment,
    isAdmin,
    setName,
    setStatusPayment,
    setStatusPrint,
    setInvoiceId,
    setOrder,
    setOrderBy,
    handleConfirmationDelete,
    handleOpenCollapse,
    partnerType,
    setPartnerType,

    salesName,
    setSalesName
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SUPERVISOR',
    allowed: ['SUPERVISOR']
  });

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[
              { label: '#' },
              { id: 'invoiceNumber', label: 'Nomor Invoice', sort: true },
              { id: 'name', label: 'Nama Customer', sort: true },
              { id: 'createdAt', label: 'Tgl Invoice', sort: true },
              { id: 'nameSales', label: 'NAMA SALES', sort: true },
              { id: 'totalPrice', label: 'Total Tagihan', sort: true },
              { id: 'restPay', label: 'Sisa Tagihan', sort: true },
              { id: 'statusPayment', label: 'Status', sort: true, align: 'center' }
            ]}
          />

          <TableRow>
            <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
              <TextField id='invoiceId' placeholder='Nomor Invoice' value={invoiceId} onChange={event => setInvoiceId(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='name' placeholder='Nama Customer' value={name} onChange={event => setName(event.target.value)} />
            </TableCellHead>
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable}>
              <TextField id='salesName' placeholder='Sales' value={salesName} onChange={event => setSalesName(event.target.value)} />
            </TableCellHead>{' '}
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable} />
            <TableCellHead variant='head' className={classes.cellTable3}>
              <TextField
                select
                id='statusPayment'
                value={statusPayment}
                onChange={event => setStatusPayment(event.target.value as string)}
                className={classes.selectStatus}
              >
                <MenuItem selected key={1} value='' disabled>
                  Pilih Pembayaran
                </MenuItem>
                <MenuItem key={2} value='PAID'>
                  Lunas
                </MenuItem>
                <MenuItem key={3} value='UNPAID'>
                  Belum Lunas
                </MenuItem>
                <MenuItem key={4} value='CANCEL'>
                  Batal
                </MenuItem>
              </TextField>
            </TableCellHead>
            <TableCellHead />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingData ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                key={index}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                invoicePayment={invoicePayment}
                invoice={dummyInvoice}
                handlePaymentConfirm={handlePaymentConfirm}
                loadingPayment={loadingPayment}
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                onDelete={handleConfirmationDelete(0)}
                handleOpenCollapse={handleOpenCollapse(index, 0)}
                loadingItem={loadingItem}
              />
            ))
          ) : invoices && invoices.length > 0 ? (
            invoices.map((value, index) => (
              <BodyRow
                key={index}
                index={index}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                isLoading={isLoadingData}
                invoice={value}
                handlePaymentConfirm={handlePaymentConfirm}
                loadingPayment={loadingPayment}
                invoicePayment={invoicePayment}
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                onDelete={handleConfirmationDelete(value.id)}
                handleOpenCollapse={handleOpenCollapse(index, value.id)}
                loadingItem={loadingItem}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                Data Tidak Tersedia.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default InvoiceTable;
