import React, { FC } from 'react';
import { Button, Dialog, FormHelperText, Grid, IconButton, InputBase, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { AddOutlined, CloseRounded, RemoveOutlined } from '@material-ui/icons';
import { RED, PRIMARY, PRIMARY_SECONDARY, WHITE } from 'constants/colors';
import clsx from 'clsx';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '0.8em'
  },
  padding: {
    padding: '1em'
  },

  button: {
    borderRadius: '5px',
    padding: '7px 0'
  },
  inputContainer: {
    borderRadius: '0.3em',
    border: `1px solid ${PRIMARY}`
  },
  iconButton: {
    background: PRIMARY,
    '&:hover': {
      background: PRIMARY_SECONDARY
    }
  },
  icon: {
    color: WHITE
  },

  containerEmpty: {
    minHeight: '15em',
    rowGap: '1em'
  }
}));
interface Props {
  open: boolean;
  createDelevery: CollyType | undefined;
  handleCollyUpdate: (index: number, action: boolean) => void;
  setCreateDeleveryUpdate: React.Dispatch<React.SetStateAction<CollyType[]>>;
  handleSaveUpdate: () => void;
}
const CollyUpdate: FC<Props> = ({ open, createDelevery, handleCollyUpdate, setCreateDeleveryUpdate, handleSaveUpdate }) => {
  const classes = useStyles();

  const isDisabled =
    createDelevery &&
    (createDelevery.items.some(val => val.totalItem < 0 || val.totalItem > ((val.totalItemBefore && val.totalItemBefore) || 0)) ||
      Object.values(createDelevery).some((value, key) => (key < 2 ? value === '' : false)));

  const handleCounterItem = (index: number, action: string = 'increase') => {
    if (createDelevery) {
      const tempData = [...(createDelevery && createDelevery.items)];
      tempData[index].totalItem = action.includes('increase') ? tempData[index].totalItem + 1 : tempData[index].totalItem - 1;
      setCreateDeleveryUpdate([{ ...createDelevery, totalItem: tempData.reduce((value, current) => value + current.totalItem, 0), items: tempData }]);
    }
  };
  const handleChangeItem = (index: number, qty: number) => {
    createDelevery &&
      setCreateDeleveryUpdate([
        { ...createDelevery, items: createDelevery.items.map((value, key) => (key === index ? { ...value, totalItem: qty } : value)) }
      ]);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    createDelevery && setCreateDeleveryUpdate([{ ...createDelevery, [event.target.id]: event.target.value }]);
  return (
    <Dialog open={open} fullWidth>
      <Grid xs={12} className={clsx(classes.container, classes.padding)} container>
        <Grid xs={12} container alignItems='center'>
          <CloseRounded style={{ cursor: 'pointer' }} onClick={() => handleCollyUpdate(0, false)} />
          <Typography variant='h5'>Update Koli</Typography>
        </Grid>
        <Grid xs={12} container className={classes.container}>
          <TextField id='name' required fullWidth label='Nama Koli' value={(createDelevery && createDelevery.name) || ''} onChange={handleChange} />
          <TextField
            id='packName'
            required
            fullWidth
            label='Keterangan'
            value={(createDelevery && createDelevery.packName) || ''}
            onChange={handleChange}
          />
          <Grid xs={6}>
            <Typography variant='h6' color='textSecondary'>
              Nama Produk
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Typography variant='h6' color='textSecondary' align='center'>
              Kuantitas
            </Typography>
            <Grid xs={4} />
          </Grid>
          {createDelevery &&
            createDelevery.items.map((value, key) => (
              <Grid xs={12} container key={key}>
                <Grid xs={6}>
                  <Typography variant='h6'>{value.productName}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography align='center' variant='h6'>
                    {value.totalItemBefore || 0}
                  </Typography>
                </Grid>
                <Grid xs={4} container justify='space-between' alignItems='center'>
                  <IconButton aria-label='remove' component='div' size='small' className={classes.iconButton} disabled={value.totalItem < 1}>
                    <RemoveOutlined className={classes.icon} onClick={() => handleCounterItem(key, 'decrease')} />
                  </IconButton>
                  <Grid
                    className={classes.inputContainer}
                    xs={6}
                    style={{
                      border: value.totalItem < 0 || value.totalItem > (value.totalItemBefore || 0) ? '1px solid ' + RED : '1px solid ' + PRIMARY
                    }}
                  >
                    <InputBase
                      value={value.totalItem}
                      inputProps={{ min: 0, style: { textAlign: 'center' }, inputMode: 'numeric' }}
                      onChange={e => handleChangeItem(key, +e.target.value)}
                    />
                  </Grid>
                  <IconButton
                    aria-label='add'
                    disableFocusRipple={false}
                    disableRipple={true}
                    component='div'
                    disabled={value.totalItem >= (value.totalItemBefore || 0)}
                    size='small'
                    className={classes.iconButton}
                  >
                    <AddOutlined className={classes.icon} onClick={() => handleCounterItem(key)} />
                  </IconButton>

                  {(value.totalItem < 0 || value.totalItem > (value.totalItemBefore || 0)) && (
                    <Grid xs={12} container justify='center'>
                      <FormHelperText error={true}>
                        <Typography align='center'>
                          Harus lebih besar dari 0 dan tidak boleh melebehi Qty Awal ({value.totalItemBefore || 0})
                        </Typography>
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid xs={12} container>
          <Button size='small' fullWidth className={classes.button} color='primary' disabled={isDisabled} onClick={handleSaveUpdate}>
            Simpan
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CollyUpdate;
