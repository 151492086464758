import { Button, CircularProgress, Grid, Tab, Tabs } from '@material-ui/core';
import { a11yProps } from 'components';
import React from 'react';

interface Props {
  tabs: { tab: number; handleTab: (event: React.ChangeEvent<{}>, newValue: number) => void };
  labels: string[];
  onClick: { isLoadingDownload: boolean; handleDownload: () => void };
}
const TabSection = ({ tabs, labels, onClick }: Props) => {
  return (
    <Grid xs={12} container justify='space-between'>
      <Grid xs={6}>
        <Tabs
          value={tabs.tab}
          onChange={tabs.handleTab}
          indicatorColor='primary'
          textColor='primary'
          aria-label='tabs type report'
          variant='fullWidth'
        >
          {labels.map((label, key) => (
            <Tab label={label} {...a11yProps(key)} key={key} />
          ))}
        </Tabs>
      </Grid>
      <Grid xs={6} container justify='flex-end' alignItems='center'>
        <Button variant='contained' onClick={onClick.handleDownload} color='primary' disabled={onClick.isLoadingDownload} size='small'>
          {onClick.isLoadingDownload ? <CircularProgress color='primary' size={20} /> : 'Download PDF'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TabSection;
