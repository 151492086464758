import { Button, Container, Grid, makeStyles, Modal, Theme, Tooltip, Typography } from '@material-ui/core';
import { Add, Today } from '@material-ui/icons';
import Refresh from '@material-ui/icons/Refresh';
import { Pagination } from '@material-ui/lab';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import DateRangeFilter from 'components/DateRangeFilter';
import { PRIMARY, WHITE } from 'constants/colors';
import { DELIVERY_ORDER_BASE_URL, DELIVERY_ORDER_PRINT } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import useDebounced from 'hooks/useDebounced';
import useRouter from 'hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { dummyMetaData } from 'utils/dummy';
import ModalPage from './components/ModalPage';
import DeliveryOrderTable from './DeliveryOrderTable';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: PRIMARY,
    color: WHITE,
    '&:hover': {
      backgroundColor: PRIMARY,
      transform: 'scale(1.1)'
    }
  }
}));
const DeliveryOrders = () => {
  const classes = useStyles();
  const { history } = useRouter();
  let cancelToken: CancelTokenSource = axios.CancelToken.source();
  const [deliveryOrders, setDeliveryOrders] = useState<DeliveryOrders[]>([]);
  const [metaDelivery, setMetaDelivery] = useState<MetaData>(dummyMetaData);
  const [deliveryNumber, setDeliveryNumber] = useState('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [partnerName, setPartnerName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [printWarning, setPrintWarning] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isDeliveryPrint, setIsDeliveryPrint] = useState<{ isLoading: boolean; id: number }>({
    isLoading: false,
    id: 0
  });

  const handleCreateDO = () => history.push('/surat-jalan/tambah');

  const deliveryPrint = async (id: number) => {
    setIsDeliveryPrint(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(DELIVERY_ORDER_PRINT(id), { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      setDeliveryOrders(prev => {
        return prev.map(deliveryOrder =>
          deliveryOrder.id === id ? { ...deliveryOrder, ['printCount']: deliveryOrder.printCount + 1 } : { ...deliveryOrder }
        );
      });
      setIsDeliveryPrint({ isLoading: false, id: 0 });
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handlePrintWarning = (isAction: string = 'close') => {
    setPrintWarning(false);
    if (isAction.includes('close')) return;
    deliveryPrint(isDeliveryPrint.id);
  };

  const handleDeliveryPrint = (id: number) => {
    setIsDeliveryPrint(prev => ({ ...prev, id }));

    if (deliveryOrders.find(val => val.id === id) && deliveryOrders.find(val => val.id === id)!.printCount > 0) {
      setPrintWarning(true);
    } else {
      deliveryPrint(id);
    }
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    setPartnerName('');
    setDeliveryNumber('');
    setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    setEndDate(format(new Date(), 'yyyy-MM-dd'));
    fecthDeliveryOrder();
  };

  const handleCalendarFilterClick = () => setOpenCalendarFilter(!openCalendarFilter);

  const fecthDeliveryOrder = async () => {
    setIsLoading(true);
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (deliveryNumber) {
        params.append('deliveryNumber', deliveryNumber);
      }

      if (partnerName) {
        params.append('partnerName', partnerName);
      }
      params.append('startDate', startDate);
      params.append('endDate', endDate);
      params.append('page', currentPage.toString());

      return params;
    };
    try {
      const { data } = await axios.get(`${DELIVERY_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });

      setMetaDelivery(data.meta);
      setDeliveryOrders(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedFetchHits = useDebounced(fecthDeliveryOrder, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [currentPage, partnerName, deliveryNumber, startDate, endDate]);

  return (
    <Page title='Surat Jalan'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Surat Jalan
          </Typography>
          <Breadcrumb />
        </Grid>

        <PaperCustom>
          <Grid xs={12} container style={{ rowGap: '1em' }}>
            <Grid xs={12} container alignItems='center'>
              <Grid xs={7} container style={{ columnGap: '1em' }} alignItems='center'>
                <Grid item>
                  <Tooltip title='Memuat Ulang'>
                    <Button size='small' onClick={handleRefresh} color='inherit' className={classes.refresh}>
                      <Refresh fontSize='small' />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                    {`Menampilkan ${metaDelivery.total || 0} Surat Jalan (${metaDelivery.from || 0} - ${metaDelivery.to ||
                      0} dari ${metaDelivery.total || 0})`}
                  </Typography>

                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={5} container justify='flex-end' style={{ columnGap: '1em' }}>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='primary' variant='outlined' onClick={handleCalendarFilterClick}>
                    <Today />
                  </Button>
                </Tooltip>
                <Button onClick={handleCreateDO} size='small'>
                  <Add fontSize='small' /> Surat Jalan
                </Button>
              </Grid>
            </Grid>

            <DeliveryOrderTable
              deliveryOrders={deliveryOrders}
              isLoading={isLoading}
              deliveryNumber={deliveryNumber}
              setDeliveryNumber={setDeliveryNumber}
              handleDeliveryPrint={handleDeliveryPrint}
              isDeliveryPrint={isDeliveryPrint}
              partnerName={partnerName}
              setPartnerName={setPartnerName}
            />

            {deliveryOrders.length > 0 && (
              <Grid xs={12} container justify='center'>
                <Pagination
                  count={metaDelivery.last_page}
                  onChange={(event, page) => setCurrentPage(page)}
                  page={metaDelivery.current_page}
                  boundaryCount={2}
                  variant='outlined'
                  shape='rounded'
                />
              </Grid>
            )}
          </Grid>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
          <ModalPage
            isOpen={printWarning}
            printResource={deliveryOrders.find(val => val.id === isDeliveryPrint.id)}
            handlePrintWarning={handlePrintWarning}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default DeliveryOrders;
